import * as React from "react";
import { useState, useEffect } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ErrorDialog from "./ErrorDialog";
import InformDialog from "./InformDialog";

import ProjectTable from "./ProjectTable";
import AllEmployeesTable from "./AllEmployeesTable";
import useDialogStore from "./stores/useDialogStore";
import useMenuStore from "./stores/useMenuStore";

//Icons
import DensityMedium from '@mui/icons-material/DensityMedium';
import House from '@mui/icons-material/House';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("projectTable");
  const dialogStore = useDialogStore();
  const menuStore = useMenuStore();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [version, setVersion] = useState("");

  document.setOpenErrorDialog = setOpenErrorDialog;
  document.setErrorDialogMessage = setErrorDialogMessage;

  const handleClick = () => {
      setOpen(!open);
  };

  useEffect(() => {
    fetch("manifest.xml")
        .then(response => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => setVersion(data.getElementsByTagName("Version")[0].childNodes[0].nodeValue));
  },[])

    return (
      <div sx={{width: '100%'}}>
        {menuStore.showMenu == true &&
          <nav style={{position: 'absolute', right: '0', left: '0', display: 'flex', justifyContent: 'flex-end', zIndex: '100'}}>
            <List
              sx={{ width: 250, bgcolor: 'white', mr: '5px', mt: '5px', p: '0' }}
              component="nav"
            >
              <ListItemButton onClick={handleClick} sx={{ display:'flex', justifyContent:'flex-end' }}>
                <ListItemIcon>
                  {open ? <DensityMedium sx={{ color: 'white', fontSize: 40, bgcolor: 'green' }}/> : <DensityMedium sx={{ color: 'green', fontSize: 40 }}/>}
                </ListItemIcon>
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit sx={{ boxShadow: '5px 5px 5px #e0e0e0'}}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 3 }} onClick={() => {setActive("projectTable"); handleClick()}}>
                    <ListItemIcon>
                      <House />
                    </ListItemIcon>
                    <ListItemText primary="Projektek" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 3 }} onClick={() => {setActive("allEmployeesTable"); handleClick()}}>
                    <ListItemIcon>
                    <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Vállalkozók/személyek" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </nav>
        }
        {dialogStore.openErrorDialog && <ErrorDialog description={dialogStore.errorMessage} setOpen={dialogStore.setOpenErrorDialog} />}
        {dialogStore.openInformDialog && <InformDialog description={dialogStore.informMessage} setOpen={dialogStore.setOpenInformDialog} />}
        {active === "projectTable" && <ProjectTable />}
        {active === "allEmployeesTable" && <AllEmployeesTable />}
        {openErrorDialog == true && <ErrorDialog description={errorDialogMessage} setOpen={(e)=> setOpenErrorDialog(false) }/>}
        <div style={{padding: '2px 10px 0px 10px', backgroundColor: '#edebe9', color: '#666666', display: 'flex', justifyContent: 'space-between'}}>
          <span>Verziószám: {version}</span>
          <a href="https://ceh.hu/hu/admin/developments" target={"_blank"}>
            <HelpIcon className="icon" sx={{ color: '#666666', fontSize: '20px' }}/>
          </a>
        </div>
      </div>
    );
}
