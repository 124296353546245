
import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { BasicButton, ClickButton } from "./styles/ButtonStyles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import EmployeeSelect from './EmployeeSelect';
import AddEmployee from './AddEmployee';
import ConfirmDialogWithBtn from "./ConfirmDialogWithBtn";
import useEmployeeStore from "./stores/useEmployeeStore";
import useCoopMemberStore from "./stores/useCoopMemberStore";
import useDialogStore from "./stores/useDialogStore";
import { requestDeleteData, datagridLocalization, renderToolTip } from "./Utils";
import { URL } from "../utils/constans";

// Icons
import AddCircle from '@mui/icons-material/AddCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function EmployeeTable(props) {
    const [active, setActive] = useState("");
    const [tableEmployees, setTableEmployees] = useState(props.tableEmp);
    const [listEmployees, setListEmployees] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [deletedEmployee, setDeletedEmployee] = useState([])
    const employeeStore = useEmployeeStore();
    const coopMemberStore = useCoopMemberStore();
    const [projectEmpOnly, setProjectEmpOnly] = useState(true);
    const dialogStore = useDialogStore();
    const [warning, setWarning] = useState(false);
    
    const columns= [
        {
            field: "delete", headerName: "", width: 50, sortable: false,
            renderCell: (params) => {
              const deleteSelectedEmployee = (e) => {
                e.stopPropagation();
                const thisRow = {};
                params.api
                  .getAllColumns()
                  .filter((c) => c.field != "delete")
                  .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                  );
                  setDeletedEmployee(thisRow);
              };
              return (
                <>
                    {props.isShowed == true &&
                    <div onClick={(e) => deleteSelectedEmployee(e)}>
                        <ConfirmDialogWithBtn
                            title="Figyelem!"
                            description={
                                `Biztos, hogy törölni szeretné a kollégát ${props.multiple ?
                                    "a projekt résztvevők közül" :
                                    "a kooperáció résztvevők közül"
                                }? A múltbéli kooperációk résztvevői közül az illető nem törlődik.`
                            }
                            yes="Törlés"
                            no="Mégse"
                            setConfirm={setConfirm}
                            type="deleteIcon"
                        />
                    </div>
                    }
                </>
              )
            }
        },
        { field: 'id', headerName: 'Id', width: 130, hide: true },
        { field: 'name', headerName: 'Név', width: 130, renderCell: renderToolTip },
        { field: 'company', headerName: 'Cég', width: 130, renderCell: renderToolTip },
        { field: 'job_title', headerName: 'Szerepkör', width: 130, renderCell: renderToolTip },
        { field: 'email', headerName: 'Email cím', width: 180, renderCell: renderToolTip },
        { field: 'phone', headerName: 'Telefonszám', width: 180, renderCell: renderToolTip },
        { field: 'coopMembId', headerName: 'Id', width: 130, hide: true }
    ];

    const removeCooperationMembers = async () => {
        const resp = await requestDeleteData(`${URL}/api/cooperation_members/${deletedEmployee.coopMembId}/`, 'DELETE', deletedEmployee.coopMembId,  coopMemberStore.removeCoopMember, null, dialogStore.showErrorMessage);
        return resp;
      }

    useEffect(() => {
        if (tableEmployees.length != 0) {
            if (projectEmpOnly && !props.multiple) {
                let emp = (props.projectParticipators.filter(participator => !tableEmployees.find(emp => emp.id == participator.id)));
                setListEmployees(emp);
            } 
            else if (!projectEmpOnly && !props.multiple) {
                let emp = (employeeStore.employeesData.filter(participator => !tableEmployees.find(emp => emp.id == participator.id)));
                setListEmployees(emp);
            }
            else {
                let emp = (employeeStore.employeesData.filter(emp => !tableEmployees.includes(emp)));
                setListEmployees(emp);
            }
        }
        else {
            if (projectEmpOnly  && !props.multiple) {
                setListEmployees(props.projectParticipators);
            }
            else {
                setListEmployees(employeeStore.employeesData);
            }
        }
    },[projectEmpOnly, tableEmployees])

    const updateParticipators = () => {
        let ids = [];
        tableEmployees.forEach((employee) => {
            ids.push(employee.id);
        });
        if(props.setParticipators) {            
            props.setParticipators(ids);   
            props.updateProject(ids, () => props.renderParentComp());
        } else {
            props.renderParentComp();
        }
    }
    
    useEffect(async () => {
        if(confirm == true) {
            if(!props.multiple) {
                const resp = await removeCooperationMembers();
                if (resp != 'err') refreshEmployees();
            } 
            else {
                refreshEmployees();
            }
        }
    },[confirm])

    const refreshEmployees = () => {
        setTableEmployees(tableEmployees.filter(emp => emp.id != deletedEmployee.id));
        setListEmployees(listEmployees => [...listEmployees, deletedEmployee]);
        setConfirm(false)
    }

    useEffect(() => {
        if (warning == true) {
            updateParticipators();
            props.renderParentComp();
        }
    },[warning])

    return (
        <main>
            <KeyboardBackspaceIcon className="icon" onClick={() => {props.renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
            <h2>{props.title}</h2>
            {props.isShowed == true &&
                <>
                    <div style={{display: 'flex'}}>
                        <BasicButton color="success" variant="contained" onClick={() => {updateParticipators()}}>Mentés</BasicButton>
                        <ClickButton color="success" variant="outlined" onClick={() =>setActive('addEmployee')}>
                            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                            Vállalkozó/személy
                        </ClickButton>
                    </div>
                    {active == 'addEmployee' &&
                        <div style={{borderLeft: '2px solid green', paddingLeft: '10px', marginTop: '20px', marginBottom: '10px'}}>
                            <AddEmployee renderParentComp={() => {setActive("")}} setNewEmployee={(newEmployee) =>  setTableEmployees(tableEmployees => [...tableEmployees, newEmployee])}/>
                        </div>
                    }
                    <EmployeeSelect listEmployees={listEmployees} setListEmployees={setListEmployees} setTableEmployees={setTableEmployees} tableEmployees ={tableEmployees} multiple={props.multiple} coop_id={props.coop_id}/>
                    {!props.multiple &&
                        <FormGroup sx={{display:'block'}}>
                        <FormControlLabel  control={<Switch defaultChecked value={projectEmpOnly} onChange={(e) => setProjectEmpOnly(e.target.checked)} color="success"/>} label="összes vállalkozó/személy / projekthez tartozó vállalkozók/személyek" />
                        </FormGroup>
                    }
                    <div style={{height: '15px'}}></div>
                </>
            }
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    localeText={datagridLocalization()}
                    rows={tableEmployees}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableColumnSelector
                />         
            </div>
        </main>
    )
}