import create from 'zustand';

const useCooperationStore = create((set) => ({
    cooperationsData: [],
    setCooperationsData: (data) => {
        set({cooperationsData: data})
    },
    addCooperation: (newCooperation) => set(state => ({
        cooperationsData: [...state.cooperationsData, newCooperation]
    })),
    updateCooperation: (updatedCooperation) => set(state => ({
        cooperationsData: state.cooperationsData.map((cooperation) => {
            if(cooperation.id == updatedCooperation.id) {
                return updatedCooperation;
            } else {
                return cooperation;
            }
        })
    })),
    removeCooperation: (id) =>set(state => ({
        cooperationsData: state.cooperationsData.filter((cooperation) => cooperation.id !== id)
    })),
    activeCooperation: null,
    setActiveCooperation: (data) => {
        set({activeCooperation: data})
    },
    showInsertBtns: false,
    setShowInsertBtns: (value) => set(state => ({
        showInsertBtns: value
    })),
    disableInsertMainEntryBtn: true,
    setDisableInsertMainEntryBtn: (value) => set(state => ({
        disableInsertMainEntryBtn: value
    })),
    disableInsertSubentryBtn: true,
    setDisableInsertSubentryBtn: (value) => set(state => ({
        disableInsertSubentryBtn: value
    })),
    disableInsertMainTopicBtn: true,
    setDisableInsertMainTopicBtn: (value) => set(state => ({
        disableInsertMainTopicBtn: value
    })),
    disableInsertSubtopicBtn: true,
    setDisableInsertSubtopicBtn: (value) => set(state => ({
        disableInsertSubtopicBtn: value
    })),
    disableRemoveRowBtn: true,
    setDisableRemoveRowBtn: (value) => set(state => ({
        disableRemoveRowBtn: value
    })),
    disableSyncronisationBtn: false,
    setDisableSyncronisationBtn: (value) => set(state => ({
        disableSyncronisationBtn: value
    })),
}));

export default useCooperationStore;