import * as React from "react";
import PropTypes from "prop-types";
import Navigation from "./Navigation";
import Progress from "./Progress";
import Loading from "./Loading";

/* global console, Excel, require */

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/logo-filled.png")}
          message="Betöltés alatt."
        />
      );
    }

    return (
      <div className="ms-welcome">
        <Loading />
        <Navigation />
      </div>
    );
  }
}

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
