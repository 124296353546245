import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export const ClickButton = styled(MuiButton)(({buttoncolor="green"}) => ({
    color: buttoncolor,
    borderColor: buttoncolor,
    margin: '10px 10px 10px 0px',
    "&:hover": {borderColor: buttoncolor, backgroundColor: buttoncolor, color: 'white', "& .icon": {color: 'white'}},
}));

export const BasicButton = styled(MuiButton)(() => ({
  margin: '10px 10px 10px 0px',
}));
