import * as React from "react";
import moment from 'moment';
import 'moment/locale/hu';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";
import MenuItem from '@mui/material/MenuItem';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { URL } from "../utils/constans";

import useProjectStore from "./stores/useProjectStore";
import useEmployeeStore from "./stores/useEmployeeStore";
import EmployeeTable from "./EmployeeTable";
import useDialogStore from "./stores/useDialogStore";
import {requestFileData} from "./Utils";
import WarningDialogWithBtn from "./WarningDialogWithBtn";

// icons
import AddCircle from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function AddProject({renderParentComp}) {
    const [active, setActive] = useState("projectDetails");
    const [name, setName] = useState("");
    const [client, setClient] = useState("");
    const [place, setPlace] = useState("");
    const [logo, setLogo] = useState(null);
    const [logo2, setLogo2] = useState(null);
    const [date_of_start, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [is_archived, setArchived] = useState(false);
    const [participators, setParticipators] = useState([]);
    const projectStore = useProjectStore();   
    const employeeStore = useEmployeeStore();
    const [empData, setEmpData] =useState([]);
    const dialogStore = useDialogStore(); 

    const newProject = async () => {
        if (name != "" && client != "" && place != "") {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("client", client);
            formData.append("place", place);
            if (logo != null) formData.append("logo", logo);
            if (logo2 != null) formData.append("logo2", logo2);
            formData.append("date_of_start", date_of_start);
            formData.append("is_archived", is_archived);
            if (participators.length > 0) {
                for (var i = 0; i < participators.length; i++) {
                    formData.append("participators", participators[i]);
                }
            }
            await requestFileData(`${URL}/api/projects/`, 'POST', formData,  projectStore.addProject, renderParentComp, dialogStore.showErrorMessage);
        }
        else {
            dialogStore.showErrorMessage('A projekt nevét, a megrendelő nevét és a helyszínt kötelező megadni');
        }
    }

    const uploadhandler = (event, setFile) => {
        const file = event.target.files[0];
        setFile(file);
    }

    useEffect(() => {
        setEmpData([]);
        participators.forEach(id => {           
            employeeStore.employeesData.some(employee => {
                    if (employee.id == id) {
                       setEmpData(empData => [...empData, employee]);
                       return true;
                    };
            })        
        });
    },[participators])

    return (
        <main>
            {active === "projectDetails" &&
                <div>
                    <KeyboardBackspaceIcon className="icon" onClick={() => {renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
                    <h2>Projekt hozzáadása</h2>
                    <div style={{ display: 'flex' }}>
                        <BasicButton color="success" variant="contained" onClick={() => {newProject()}}>Mentés</BasicButton>
                        <WarningDialogWithBtn
                            title="Figyelem!"
                            description="Résztvevők hozzáadásához először el kell mentenie a projektet"
                            okay="Rendben"
                            button="projectParticipantsBtn"
                        />
                    </div>
                    <div sx={{ display: 'block' }}>
                    <TextField id="1" value={name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                    <TextField id="2" value={client} onChange={(e) => setClient(e.target.value)} label="Megrendelő neve" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                    <TextField id="3" value={place} onChange={(e) => setPlace(e.target.value)} label="Helyszín" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            locale="hu"
                            id="4"
                            label="Kezdeti dátum"
                            inputFormat="YYYY-MM-DD"
                            toolbarFormat="MMMM DD"
                            value={date_of_start}
                            onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>}
                            variant="outlined"                           
                        />
                    </LocalizationProvider>
                    <TextField
                        id="5"
                        select
                        value={is_archived}
                        label="Státusz"
                        onChange={(e) => setArchived(e.target.value)}
                        variant="outlined"
                        color="success"
                        sx={{m: '10px 20px 10px 0', width: '350px'}}
                    >
                        <MenuItem value={true}>Archivált</MenuItem>
                        <MenuItem value={false}>Aktív</MenuItem>
                    </TextField>
                    <div className="file-uploader">
                        <ClickButton>
                            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                            Projekt logó kiválasztása
                            <input id="6" onChange={(e)=>uploadhandler(e, setLogo)} type="file" accept="image/*" variant="outlined" color="success"/>
                        </ClickButton>
                        {logo != null &&
                            <>
                                <span>{logo.name}</span>
                                <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={() => setLogo(null)}/>
                            </>
                        }
                    </div>
                    <div className="file-uploader">
                        <ClickButton>
                            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                            Céh logó kiválasztása
                            <input id="6" onChange={(e)=>uploadhandler(e, setLogo2)} type="file" accept="image/*" variant="outlined" color="success"/>
                        </ClickButton>
                        {logo2 != null &&
                            <>
                                <span>{logo2.name}</span>
                                <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={() => setLogo2(null)}/>
                            </>
                        }
                    </div>
                    </div>
                    <BasicButton color="success" variant="contained" onClick={() => {newProject()}}>Mentés</BasicButton>
                </div>
            }
            {active === "employeeTable" &&
                <div>
                <EmployeeTable rows={[]} renderParentComp={() => setActive("projectDetails")} tableEmp={empData} setParticipators={setParticipators} multiple={true} title="A projekthez tartozó vállalkozók/személyek" isShowed={true}/>
                </div>
            }
        </main>
    )
}