import React from 'react'
import { ClickButton } from "./styles/ButtonStyles";
import useCooperationStore from "./stores/useCooperationStore";

// icons
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';

import {
    insertNewTopicOnThisLevel,
    insertNewTopicUnderThisLevel,
    insertNewEntryUnderThisLevel,
    insertNewEntryOnThisLevel,
  } from "../utils/manual_insert";
import { removeSelectedExcelLine } from '../utils/excel_utils';

export default function TopicAndEntryInsertBtns() {
    const cooperationStore = useCooperationStore();

  return (
    <div style={{ display: 'block', paddingBottom: '10px' }}>
        <ClickButton  variant="outlined" onClick={() => insertNewTopicOnThisLevel()} disabled={cooperationStore.disableInsertMainTopicBtn}>
            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
            Új témakör az aktuális szinten
        </ClickButton>

        <ClickButton  variant="outlined" onClick={() => insertNewTopicUnderThisLevel()} disabled={cooperationStore.disableInsertSubtopicBtn}>
            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
            Altémakör beszúrása
        </ClickButton>

        <ClickButton  variant="outlined" onClick={() => insertNewEntryOnThisLevel()} disabled={cooperationStore.disableInsertMainEntryBtn}>
            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
            Új bejegyzés az aktuális szinten
        </ClickButton>

        <ClickButton  variant="outlined" onClick={() => insertNewEntryUnderThisLevel()} disabled={cooperationStore.disableInsertSubentryBtn}>
            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
            Albejegyzés beszúrása
        </ClickButton>

        <ClickButton  buttoncolor="rgba(172, 0, 0, 0.8)" variant="outlined" onClick={() => {removeSelectedExcelLine()}} disabled={cooperationStore.disableRemoveRowBtn}>
            <RemoveCircle className="icon" sx={{ color: 'rgba(172, 0, 0, 0.8)', mr: '5px' }}/>
            Kiválasztott sor törlése
        </ClickButton>
    </div>
  )
}
