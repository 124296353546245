import * as React from "react";
import moment from 'moment';
import 'moment/locale/hu';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";
import MenuItem from '@mui/material/MenuItem';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Box from '@mui/material/Box';

import CooperationTable from "./CooperationTable";
import EmployeeTable from "./EmployeeTable";
import useProjectStore from "./stores/useProjectStore";
import useEmployeeStore from "./stores/useEmployeeStore";
import useDialogStore from "./stores/useDialogStore";
import ConfirmDialogWithBtn from "./ConfirmDialogWithBtn";
import {requestFileData, requestDeleteData} from "./Utils";
import { URL } from "../utils/constans";
import { getFile, downloadFile, getFileNameFromUrl } from "../utils/image_utils";
// icons
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import ModeEdit from '@mui/icons-material/ModeEdit';
import AddCircle from '@mui/icons-material/AddCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditProject({renderParentComp, project, setProject, isAdmin}) {
    const [active, setActive] = useState("projectDetails");
    const setName = (newName) => setProject({...project, name: newName})
    const setClient = (newClient) => setProject({...project, client: newClient})
    const setPlace = (newPlace) => setProject({...project, place: newPlace})
    const setDate = (newDateOfStart) => setProject({...project, date_of_start: newDateOfStart})
    const setArchived = (isArchived) => setProject({...project, is_archived: isArchived})
    const setParticipators = (newParticipators) =>  setProject({...project, participators: newParticipators})
    
    const [logo, setLogo] = useState(project.logo);
    const [logo2, setLogo2] = useState(project.logo2);
    const [logoBlob, setLogoBlob] = useState(null);
    const [logo2Blob, setLogo2Blob] = useState(null);
    const [confirm, setConfirm] = useState(false)
    const projectStore = useProjectStore();
    const employeeStore = useEmployeeStore();
    const [empData, setEmpData] =useState([]);
    const dialogStore = useDialogStore();

    const isEmpty = (logo) => logo == "" || logo == null;

    const updateProject = async (members, renderPrevComp) => {
        if (project.name != "" && project.client != "" && project.place != "") {
            const formData = new FormData();
            formData.append("id", project.id);
            formData.append("name", project.name);
            formData.append("client", project.client);
            formData.append("place", project.place);
            if (logo != null && (logo.name || logo == "")) formData.append("logo", logo);
            if (logo2 != null && (logo2.name || logo2 == "")) formData.append("logo2", logo2);
            formData.append("date_of_start", project.date_of_start);
            formData.append("is_archived", project.is_archived);
            if (members.length > 0) {
                for (var i = 0; i < members.length; i++) {
                formData.append("participators", members[i]);
                }
                }
            await requestFileData(`${URL}/api/projects/${project.id}/`, 'PATCH', formData,  projectStore.updateProject, renderPrevComp, dialogStore.showErrorMessage);
        }
        else {
            dialogStore.showErrorMessage('A projekt nevét, a megrendelő nevét és a helyszínt kötelező megadni');
        }
    }

    const removeProject = async () => {
        await requestDeleteData(`${URL}/api/projects/${project.id}/`, 'DELETE', project.id,  projectStore.removeProject, renderParentComp, dialogStore.showErrorMessage);
    }

    useEffect(() => {
        if(confirm == true) {
            removeProject();
        }
      },[confirm]);

    useEffect(() => {
        getFile(project.logo, {blob: true, img: true}).then((res)=>{
            setLogo(res.img.data);
            setLogoBlob(res.blob)
        });
    },[!isEmpty(project.logo)]);

    useEffect(() => {
        getFile(project.logo2, {blob: true, img: true}).then((res)=>{
            setLogo2(res.img.data);
            setLogo2Blob(res.blob)
        });
    },[!isEmpty(project.logo2)]);

    useEffect(() => {
        setEmpData([]);
        if (project.participators) {
            project.participators.forEach(id => {
                employeeStore.employeesData.some(employee => {
                        if (employee.id == id) {
                           setEmpData(empData => [...empData, employee]);
                           return true;
                        };
                })
            });
        }
    },[project.participators])

    const uploadhandler = (event, setFile) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const updateLogo = (value) => {
        project.logo=value;
        setLogo(value);
    }
    const updateLogo2 = (value) => {
        project.logo2=value;
        setLogo2(value);
    }

    const deleteLogo = () => {
        updateLogo("");
    }

    const deleteLogo2 = () => {
        updateLogo2("");
    }

    return (
        <main>
            {active === "projectDetails" &&
                <div>
                <KeyboardBackspaceIcon className="icon" onClick={() => {renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
                    <div style={{display: 'flex'}}>
                        <h2>Projekt szerkesztése</h2>
                    </div>
                    <div style={{ display: 'block', paddingBottom: '10px' }}>
                    <BasicButton color="success" variant="contained" onClick={() => {updateProject(project.participators, renderParentComp)}}>Mentés</BasicButton>
                    <ClickButton variant="outlined" onClick={() => setActive("employeeTable")}>
                        <PeopleIcon className="icon" sx={{ color: 'green', mr: '5px' }}></PeopleIcon>
                        Projektrésztvevők
                    </ClickButton>
                    <ClickButton variant="outlined" onClick={() => setActive("editCooperationTable")}>
                        <ArticleIcon className="icon" sx={{ color: 'green', mr: '5px' }}></ArticleIcon>
                        Kooperációk
                    </ClickButton>
                    </div>
                    <div>
                        <TextField id="1" value={project.name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                        <TextField id="2" value={project.client} onChange={(e) => setClient(e.target.value)} label="Megrendelő neve" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                        <TextField id="3" value={project.place} onChange={(e) => setPlace(e.target.value)} label="Helyszín" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                locale="hu"
                                id="4"
                                label="Kezdeti dátum"
                                inputFormat="YYYY-MM-DD"
                                toolbarFormat="MMMM DD"
                                value={project.date_of_start}
                                onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>}
                                variant="outlined"
                            />
                        </LocalizationProvider>
                        <TextField
                            id="5"
                            select
                            value={project.is_archived}
                            label="Státusz"
                            onChange={(e) => setArchived(e.target.value)}
                            variant="outlined"
                            color="success"
                            sx={{m: '10px 20px 10px 0', width: '350px'}}
                        >
		                    <MenuItem value={true}>Archivált</MenuItem>
                            <MenuItem value={false}>Aktív</MenuItem>
                        </TextField>
                        <div className="file-uploader">
                            {isEmpty(logo) &&
                                <ClickButton>
                                    <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                    Projekt logó hozzáadása
                                    <input id="6" onChange={(e) => uploadhandler(e, updateLogo)} type="file" accept="image/*" variant="outlined" color="success" />
                                </ClickButton>
                            }
                            {!isEmpty(logo) &&
                                <>
                                    {!logo.name &&
                                        <div sx={{display: 'block'}}>
                                            <Box component="img" sx={{ height: '70px', width: '70px', objectFit: 'contain', borderRadius: '10%' }}  src={logo} onClick={(e)=>downloadFile(logoBlob, getFileNameFromUrl(logo))}/>
                                            <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={deleteLogo}/>
                                        </div>
                                    }
                                    <ClickButton>
                                        <ModeEdit className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                        Projekt logó cseréje
                                        <input id="7" onChange={(e) => uploadhandler(e, updateLogo)} type="file" accept="image/*" variant="outlined" color="success" />
                                    </ClickButton>
                                </>

                            }
                            {logo != null && logo.name &&
                                <>
                                    <span>{logo.name}</span>
                                    <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={deleteLogo}/>
                                </>
                            }
                        </div>
                        <div className="file-uploader">
                            {isEmpty(logo2) &&
                                <ClickButton>
                                    <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                    Céh logó hozzáadása
                                    <input id="8" onChange={(e) => uploadhandler(e, updateLogo2)} type="file" accept="image/*" variant="outlined" color="success" />
                                </ClickButton>
                            }
                            {!isEmpty(logo2) &&
                                <>
                                    {!logo2.name &&
                                        <div sx={{display: 'block'}}>
                                            <Box component="img" sx={{ height: '70px', width: '70px', objectFit: 'contain', borderRadius: '10%' }} src={logo2} onClick={(e)=>downloadFile(logo2Blob, getFileNameFromUrl(logo2))} />
                                            <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={deleteLogo2}/>
                                        </div>
                                    }
                                    <ClickButton>
                                        <ModeEdit className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                        Céh logó cseréje
                                        <input id="9" onChange={(e) => uploadhandler(e, updateLogo2)} type="file" accept="image/*" variant="outlined" color="success" />
                                    </ClickButton>
                                </>
                            }
                            {logo2 != null && logo2.name  &&
                                <>
                                    <span>{logo2.name}</span>
                                    <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={deleteLogo2}/>
                                </>
                            }
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <BasicButton color="success" variant="contained" onClick={() => {updateProject(project.participators, renderParentComp)}}>Mentés</BasicButton>
                        {isAdmin == true &&
                            <ConfirmDialogWithBtn
                                title="Figyelem!"
                                description="Biztos, hogy törölni szeretné a projektet?"
                                yes="Törlés"
                                no="Mégse"
                                setConfirm={setConfirm}
                                type="deleteButton"
                            />
                        }
                    </div>
                </div>
            }
            {active === "employeeTable" &&
                <div>
                    <EmployeeTable rows={[]} renderParentComp={() => setActive("projectDetails")} updateProject={updateProject} tableEmp={empData} setParticipators={setParticipators} multiple={true} title="A projekthez tartozó vállalkozók/személyek" isShowed={true}/>
                </div>
            }
            {active === "editCooperationTable" &&
                <CooperationTable renderParentComp={() => setActive("projectDetails")} project_id={project.id} participators={project.participators}/>
            }
        </main>
    )
}
