import { getAccessToken, handleErrors } from "./excel_utils";
import { getCookie } from "../components/Utils";

const getFile = async (file, format = { blob: false, img: true }, showErrorMessage=null) => {
  if (!file) return null;
  try {
    const resp = await fetch(file, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getAccessToken()),
        'X-CSRFToken': getCookie('csrftoken') ,
      },
    });

    if (!resp.ok) {
      const responseMessage = JSON.stringify(await resp.json()); 
      throw { status: resp.status, responseMessage }
    }
    const fileData = await resp.blob();
    // Convert fileData to dataUrl and resolve
    if (format.img) {
      const imgData = await blobToData(fileData);
      if (format.blob) return { blob: fileData, img: imgData };
      else return imgData;
    } else if (format.blob) return fileData;
    else throw { status: "-", name: "Nem várt hiba", message: "Paraméter hiba a getFile függvényben." };
  } catch (error) {
    // If error is a dictionary, stringify it to be able to show in the component.
    // .toString() doesn't stringify dictionaries in JavaScript, that's why this extra
    // step is needed here...
    if (error.constructor == Object) {
      error = JSON.stringify(error)
    }
    const errorMessage = `A kép elérése sikertelen volt. [${error.toString()}] (${file})`
    handleErrors(error, showErrorMessage, errorMessage);
    return null;
  }
};

const cutBase64 = (BlobToBase64) => {
  let startIndex = BlobToBase64.toString().indexOf("base64,");
  let base64 = BlobToBase64.toString().substr(startIndex + 7);
  return base64;
};

const blobToData = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (readerevent) => {
      const image = new Image();
      image.src = readerevent.target.result;
      image.onload = (e) => {
        const height = e.target.height;
        const width = e.target.width;
        const size = { height: height, width: width };
        resolve({ data: readerevent.target.result, size: size });
      };
    };
    reader.onerror = () => reject();
    reader.readAsDataURL(blob);
  });
};

const downloadFile = (blob, filename) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

const getFileNameFromUrl = (url) => {
  let arr = url.split("/");
  return arr.length > 1 ? arr[arr.length - 1] : "hiba_a_feldolgozas_kozben.txt";
};

export { getFile, cutBase64, downloadFile, getFileNameFromUrl };
