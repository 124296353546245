import * as React from "react";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { BasicButton } from "./styles/ButtonStyles";

import useEmployeeStore from "./stores/useEmployeeStore";
import useDialogStore from "./stores/useDialogStore";
import {requestData} from "./Utils";
import { URL } from "../utils/constans";
// Icons
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


export default function AddEmployee({renderParentComp, setNewEmployee}) {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [job_title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const employeeStore = useEmployeeStore();
    const dialogStore = useDialogStore();

    const newEmployee = async () => {
        if (name != "" && company != "" && job_title != "") {
            const employee = {name, company, job_title, email, phone};
            const resp = await requestData(`${URL}/api/persons/`, 'POST', employee,  employeeStore.addEmployee, renderParentComp, dialogStore.showErrorMessage); 
            if (resp != null) {
                setNewEmployee(resp);
            }
        } else {
            dialogStore.showErrorMessage("A kolléga nevét, cégét és szerepkörét kötelező megadni.");
        }
    }

    return (
        <main>
             <KeyboardBackspaceIcon className="icon" onClick={() => {renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
            <h2>Vállalkozó/személy hozzáadása</h2>
            <BasicButton color="success" variant="contained" onClick={() => {newEmployee()}}>Mentés</BasicButton>
            <div sx={{ display: 'block' }}>
                <TextField id="1" value={name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                <TextField id="2" value={company} onChange={(e) => setCompany(e.target.value)} label="Cég" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="3" value={job_title} onChange={(e) => setTitle(e.target.value)} label="Szerepkör" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="4" value={email} onChange={(e) => setEmail(e.target.value)} label="Email cím" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="5" value={phone} onChange={(e) => setPhone(e.target.value)} label="Telefonszám" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
            </div>
            <BasicButton color="success" variant="contained" onClick={() => {newEmployee()}}>Mentés</BasicButton>
        </main>
    )
}