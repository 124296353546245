import * as React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";
import { useState, useEffect } from 'react';
import { datagridLocalization, renderToolTip } from "./Utils";

import AddCooperation from "./AddCooperation";
import EditCooperation from "./EditCooperation";
import useCooperationStore from "./stores/useCooperationStore";

// icons
import ArticleIcon from '@mui/icons-material/Article';
import ModeEdit from '@mui/icons-material/ModeEdit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function CooperationTable(props) {
    const [active, setActive] = useState("cooperationTable");
    const [selectedCooperation, setSelectedCooperation] = useState({});
    const [cooperations, setCooperations] = useState([]);
    const cooperationStore = useCooperationStore();

    useEffect(() => {
      let value = cooperationStore.cooperationsData.filter(coop => coop.project == props.project_id)
      setCooperations(value)
    },[cooperationStore.cooperationsData])
    
    const coopColumns= [
        {
            field: "edit", headerName: "", width: 50, sortable: false,
            renderCell: (params) => {
              const onClick = (e) => {
                e.stopPropagation();
                const thisRow = {}; 
                params.api
                  .getAllColumns()
                  .filter((c) => c.field !== "edit")
                  .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                  );
                setSelectedCooperation(thisRow);
              }; 
              return (
                  <ModeEdit className="icon" onClick={(e) => {onClick(e); setActive("editCooperation")}} sx={{ color: 'green', m: '5px', mr: '10px' }} />
              )
            }
        },
        { field: 'id', headerName: 'Id', width: 150, hide: true },
        { field: 'name', headerName: 'Név', width: 150, renderCell: renderToolTip },
        { field: 'date', headerName: 'Dátum', width: 150 },
        { field: 'attachments_url', headerName: 'Link', width: 150, hide: true },
        { field: 'place', headerName: 'Helyszín', width: 150 },
        { field: 'attachment', headerName: 'Fájl', width: 150, hide: true },
    ];
    
    return (
        <main>
            {active === "cooperationTable" &&
                <div>
                  <KeyboardBackspaceIcon className="icon" onClick={() => props.renderParentComp()} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
                  <h2>Kooperációk a projekthez</h2>
                  <div>
                    <BasicButton color="success" variant="contained" onClick={() => props.renderParentComp()}>Kooperációk mentése</BasicButton>
                    <ClickButton variant="outlined" onClick={() =>setActive("newCooperation")}>
                            <ArticleIcon className="icon" sx={{ color: 'green', mr: '5px', ml: '15px' }}></ArticleIcon>
                            Kooperáció hozzáadása
                    </ClickButton>
                  </div>
                  <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                          localeText={datagridLocalization()}
                          rows={cooperations}
                          columns={coopColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          disableColumnSelector
                      />         
                  </div>
                </div>
            }
            {active === "newCooperation" && <AddCooperation renderParentComp={() => setActive("cooperationTable")} project_id={props.project_id} participators={props.participators}/>}
            {active === "editCooperation" && <EditCooperation renderParentComp={() => setActive("cooperationTable")} project={{id:props.project_id}} participators={props.participators} cooperation={selectedCooperation}/>}
        </main>
    )
}