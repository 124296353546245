import * as React from "react";
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { BasicButton } from "./styles/ButtonStyles";
import ConfirmDialogWithBtn from "./ConfirmDialogWithBtn";

import useEmployeeStore from "./stores/useEmployeeStore";
import useDialogStore from "./stores/useDialogStore";
import { requestData, requestDeleteData } from "./Utils";
import { URL } from "../utils/constans";

// Icons
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function EditEmployee({renderParentComp, employee}) {
    const [name, setName] = useState(employee.name);
    const [company, setCompany] = useState(employee.company);
    const [job_title, setTitle] = useState(employee.job_title);
    const [email, setEmail] = useState(employee.email);
    const [phone, setPhone] = useState(employee.phone);
    const [confirm, setConfirm] = useState(false);
    const employeeStore = useEmployeeStore();
    const dialogStore = useDialogStore();

    const updateEmployee = async () => {
        if (name != "" && company != "" && job_title != "") {
        const updatedEmployee = {id: employee.id, name, company, job_title, email, phone};

        await requestData(`${URL}/api/persons/${employee.id}/`, 'PUT', updatedEmployee,  employeeStore.updateEmployee, renderParentComp, dialogStore.showErrorMessage);
    
        } else {
            dialogStore.showErrorMessage("A kolléga nevét, cégét és szerepkörét kötelező megadni.");
        }
    }

    const removeEmployee = async () => {
        await requestDeleteData(`${URL}/api/persons/${employee.id}/`, 'DELETE', employee.id,  employeeStore.removeEmployee, renderParentComp, dialogStore.showErrorMessage);
    }

    useEffect(() => {
        if(confirm == true) {
        removeEmployee();    
        }   
      },[confirm]);

    return (
        <main>
            <KeyboardBackspaceIcon className="icon" onClick={() => {renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
            <h2>Vállalkozó/személy szerkesztése</h2>
            <BasicButton color="success" variant="contained" onClick={() => {updateEmployee()}}>Mentés</BasicButton>
            <div sx={{ display: 'block' }}>
                <TextField id="1" value={name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                <TextField id="2" value={company} onChange={(e) => setCompany(e.target.value)} label="Cég" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="3" value={job_title} onChange={(e) => setTitle(e.target.value)} label="Szerepkör" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="4" value={email} onChange={(e) => setEmail(e.target.value)} label="Email cím" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
                <TextField id="5" value={phone} onChange={(e) => setPhone(e.target.value)} label="Telefonszám" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}} />
            </div>
            <div style={{display: 'flex'}}>
                <BasicButton color="success" variant="contained" onClick={() => {updateEmployee()}}>Mentés</BasicButton>
                <ConfirmDialogWithBtn
                    title="Figyelem!"
                    description="Biztos, hogy törölni szeretné a kollégát?"
                    yes="Törlés"
                    no="Mégse"
                    setConfirm={setConfirm}
                    type="deleteButton"
                />
            </div>
        </main>
    )
}