import * as React from 'react';
import { useState, useEffect } from 'react';
import { BasicButton } from "./styles/ButtonStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import FileOpen from '@mui/icons-material/FileOpen';

export default function AlertDialog({title, description, yes, no, setConfirm, type, openRequired = null}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

    return (
      <div>
        { type == "deleteButton" &&
          <BasicButton color="warning" variant="contained" onClick={handleOpen}>Törlés</BasicButton>
        }
        { type == "deleteIcon" &&
          <DeleteIcon color="warning" variant="contained" onClick={handleOpen}/>
        }
        { type == "fileOpenIcon " &&
          <Tooltip title="Összes kooperáció betöltése (a dátum állítható)">
            <FileOpen className="icon" sx={{ color: 'green', m: '5px' }} onClick={async (e) => { if (openRequired && await openRequired(e, true)) handleOpen(); }}/>
          </Tooltip>
        }
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
            <ErrorOutlineIcon sx={{fontSize: 60}}/>
            <div sx={{fontSize: '20px'}}>
                {title}
            </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{textAlign: "center"}}>
                {description}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center'}}>
              <BasicButton onClick={() => {setConfirm(false); handleClose()}} color="success" variant="contained">{no}</BasicButton>
              <BasicButton onClick={() => {setConfirm(true); handleClose()}} color="warning" variant="contained" autoFocus>
                {yes}
              </BasicButton>
            </DialogActions>
          </Dialog>
      </div>
    );
}