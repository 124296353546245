import create from 'zustand';

const useEmployeeStore = create((set) => ({
    employeesData: [],
    setEmployeesData: (data) => {
        set({employeesData: data})
    },
    addEmployee: (newEmployee) => set(state => ({
        employeesData: [...state.employeesData, newEmployee]
    })),
    updateEmployee: (updatedEmployee) => set(state => ({
        employeesData: state.employeesData.map((employee) => {
            if(employee.id == updatedEmployee.id) {
                return updatedEmployee;
            } else {
                return employee;
            }
        })
    })),
    removeEmployee: (id) =>set(state => ({
        employeesData: state.employeesData.filter((employee) => employee.id !== id)
    })),
}));

export default useEmployeeStore;