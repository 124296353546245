import * as React from "react";
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ClickButton } from "./styles/ButtonStyles";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import useEmployeeStore from "./stores/useEmployeeStore";
import useMenuStore from "./stores/useMenuStore";
import { datagridLocalization, renderToolTip } from "./Utils";

// icons
import ModeEdit from '@mui/icons-material/ModeEdit';
import AddCircle from '@mui/icons-material/AddCircle';

export default function AllEmployeesTable() {
  const [active, setActive] = useState("allEmployees");
  const employeesData = useEmployeeStore((state) => state.employeesData);
  const [employee, setEmployee] = useState({});
  const menuStore = useMenuStore();
  
  const columns= [
    {
      field: "edit",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: (params) => {
        const editSelectedEmployee = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {}; 
          api
            .getAllColumns()
            .filter((c) => c.field !== "edit")
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          setEmployee(thisRow);
        };
  
        return <ModeEdit className="icon" onClick={(e) => {setActive("editEmployee"); editSelectedEmployee(e); menuStore.setShowMenu(false)}} sx={{ color: 'green', m: '5px' }} />
      }
    },
    { field: 'id', headerName: 'Id', width: 130, hide: true },
    { field: 'name', headerName: 'Név', width: 130, renderCell: renderToolTip },
    { field: 'company', headerName: 'Cég', width: 130, renderCell: renderToolTip },
    { field: 'job_title', headerName: 'Szerepkör', width: 130, renderCell: renderToolTip  },
    { field: 'email', headerName: 'Email cím', width: 180, renderCell: renderToolTip  },
    { field: 'phone', headerName: 'Telefonszám', width: 180, renderCell: renderToolTip  },
  ];

    return (
      <main className="ms-welcome__main">
        {active === "allEmployees" &&
          <>
              <h2 style={{paddingTop: '32px'}}>Vállalkozók/személyek</h2>
              <div>
              <ClickButton variant="outlined" onClick={() => {setActive("newEmployee"); menuStore.setShowMenu(false)}}>
                <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}></AddCircle>
                Új vállalkozó/személy
              </ClickButton>
              </div>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  localeText={datagridLocalization()}
                  rows={employeesData}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableColumnSelector
                />
              </div>
          </>
        }
        {active === "newEmployee" && <AddEmployee renderParentComp={() => {setActive("allEmployees"); menuStore.setShowMenu(true)}} />}
        {active === "editEmployee" && <EditEmployee renderParentComp={() => {setActive("allEmployees"); menuStore.setShowMenu(true)}} employee={employee}/>}
      </main>
    );
}
