import * as React from "react";
import moment from 'moment';
import { useState } from "react";
import 'moment/locale/hu';
import { requestFileData, fetchData } from "./Utils";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { URL } from "../utils/constans";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";

// Stores
import useCooperationStore from "./stores/useCooperationStore";
import useDialogStore from "./stores/useDialogStore";
import useProjectStore from "./stores/useProjectStore";
import useCoopMemberStore from "./stores/useCoopMemberStore";

//Icons
import AddCircle from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


export default function AddCooperation({project_id, renderParentComp}) {
    const [name, setName] = useState("");
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [url, setUrl] = useState("");
    const [place, setPlace] = useState("");
    const [attachment, setAttachment] = useState(null);
    const cooperationStore = useCooperationStore();
    const dialogStore = useDialogStore();
    const projectStore = useProjectStore(); 
    const coopMemberStore = useCoopMemberStore();

    const newCooperation = async () => {
        if (name != "") {
            const formData = new FormData();
            formData.append("project", project_id);
            formData.append("name", name);
            formData.append("date", date);
            formData.append("attachments_url", url);
            formData.append("place", place);
            if (attachment != null) formData.append("attachment", attachment);
            
            const resp = await requestFileData(`${URL}/api/cooperations/`, 'POST', formData,  cooperationStore.addCooperation, renderParentComp, dialogStore.showErrorMessage);
            if (resp !== "err") {
                const projectToUpdate = projectStore.projectsData.find(project => project.id === project_id);
                projectToUpdate.cooperations = [...projectToUpdate.cooperations, resp]
                projectStore.updateProject(projectToUpdate);
                await fetchData(`${URL}/api/cooperation_members`, coopMemberStore.setCoopMembersData, dialogStore.showErrorMessage);
            }
        } else {
            dialogStore.showErrorMessage("A kooperáció nevét kötelező megadni.");
        }
    }

    const uploadhandler = (event) => {
        const file = event.target.files[0];
        setAttachment(file);
    }

    return (
        <main>
            <KeyboardBackspaceIcon className="icon" onClick={() => renderParentComp()} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
            <h2>Kooperáció hozzáadása</h2>
            <BasicButton color="success" variant="contained" onClick={() => newCooperation()}>Mentés</BasicButton>
            <div sx={{ display: 'block' }}>
                <TextField id="1" value={name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            locale="hu"
                            id="2"
                            label="Dátum"
                            inputFormat="YYYY-MM-DD"
                            toolbarFormat="MMMM DD"
                            value={date}
                            onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>}
                            variant="outlined"                           
                        />
                </LocalizationProvider>
                <TextField id="3" value={place} onChange={(e) => setPlace(e.target.value)} label="Helyszín" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                <TextField id="4" value={url} onChange={(e) => setUrl(e.target.value)} label="Kooperációs mappa link" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                <div className="file-uploader">
                    {attachment == null &&
                        <ClickButton>
                            <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                            Jelenléti ív hozzáadása
                            <input id="5" onChange={uploadhandler} type="file" accept="image/*" variant="outlined" color="success"/>
                        </ClickButton>
                    }
                    {attachment != null && 
                        <>
                            <ClickButton>
                                <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                Jelenléti ív cseréje
                                <input id="6" onChange={uploadhandler} type="file" accept="image/*" variant="outlined" color="success"/>
                            </ClickButton>
                            <span>{attachment.name}</span>
                            <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={() => setAttachment(null)}/>
                        </>
                    }
                </div>
            </div>
        </main>
    )
}