import * as React from 'react';
import { useState } from 'react';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// icons
import PeopleIcon from '@mui/icons-material/People';

export default function WarningDialogWithBtn({title, description, okay, button}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      { button == "projectParticipantsBtn" &&
      <ClickButton variant="outlined" onClick={handleOpen}>
          <PeopleIcon className="icon" sx={{ color: 'green', mr: '5px' }}></PeopleIcon>
          Projektrésztvevők
      </ClickButton>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
        <ErrorOutlineIcon sx={{fontSize: 60}}/>
        <div sx={{fontSize: '20px'}}>
            {title}
        </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <BasicButton onClick={handleClose} color="success" variant="contained">{okay}</BasicButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}