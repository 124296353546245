import * as React from "react";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ClickButton } from "./styles/ButtonStyles";
import { EMPTY, URL } from "../utils/constans";
import Tooltip from '@mui/material/Tooltip';

// Functions
import { loadCooperationsIntoExcel } from "../utils/excel_utils";
import { datagridLocalization, renderToolTip, fetchData, requestData, requestDeleteData } from "./Utils";
import { checkExistingSheets } from "../utils/excel_utils";

// Stores
import useDialogStore from "./stores/useDialogStore";
import useMenuStore from "./stores/useMenuStore";
import useProjectStore from "./stores/useProjectStore";
import useEmployeeStore from "./stores/useEmployeeStore";
import useCoopMemberStore from "./stores/useCoopMemberStore";
import useCooperationStore from "./stores/useCooperationStore";

// Components
import CoopDateDialog from "./CoopDateDialog";
import ConfirmDialogWithBtn from "./ConfirmDialogWithBtn";
import AddProject from './AddProject'
import EditProject from './EditProject'
import EditCooperation from "./EditCooperation";
import Checkbox from "@mui/material/Checkbox";
import { showLoadingWrapper } from "../utils/syncronisation_utils";

// Icons
import ModeEdit from '@mui/icons-material/ModeEdit';
import AddCircle from '@mui/icons-material/AddCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ProjectTable() {
  const [active, setActive] = useState("allProjects")
  const projectsData = useProjectStore((state) => state.projectsData)
  const setProjectsData = useProjectStore((state) => state.setProjectsData)
  const updateProject = useProjectStore((state) => state.updateProject)
  const addProject = useProjectStore((state) => state.addProject)
  const setEmployeesData = useEmployeeStore((state) => state.setEmployeesData)
  const setCoopMembersData = useCoopMemberStore((state) => state.setCoopMembersData)
  const setCooperationsData = useCooperationStore((state) => state.setCooperationsData)
  const [selectedProject, setSelectedProject] = useState({
    name: EMPTY, 
    client: EMPTY, 
    place: EMPTY, 
    logo: EMPTY, 
    logo2: EMPTY, 
    date_of_start: EMPTY, 
    is_archived: EMPTY, 
    participators: []
  }); 

  const dialogStore = useDialogStore();
  const menuStore = useMenuStore();
  const cooperationStore = useCooperationStore();
  const [orderedProjects, setOrderedProjects] = useState([]);
  const [coopDateLimit, setCoopDateLimit] = useState("2000-01-01");
  const [confirm, setConfirm] = useState(false);
  const [latestCooperation, setLatestCooperation] = useState([]);
  const [userData, setUserData] = useState({is_user_staff: false, user_id: 0});
  const [allFavourites, setAllFavourites] = useState([]);
  const [currFavProjectIds, setCurrFavProjectIds] = useState([]);
  
  useEffect(() => {
    if (confirm == true && selectedProject.id !== undefined && active == "allProjects") {
      menuStore.setShowMenu(false);
      setActive("CoopDateDialogOpen");
      setConfirm(false);
    }
  },[confirm])

  const columns= [
    {
      field: "loadCoop", headerName: "", width: 50, sortable: false,
      renderCell: (params) => {
        const loadLatestCoop = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = await getCurrentRow(params);
          const projectCoops = thisRow.cooperations;
          if (projectCoops.length > 0) {
            let latestCoop = projectCoops[0];
            for (const coop of projectCoops) {
              if (coop.date > latestCoop.date) latestCoop = coop;
            }
            setLatestCooperation(latestCoop);
            await loadCooperationsIntoExcel(thisRow, cooperationStore, latestCoop.date, latestCoop.date, dialogStore.showErrorMessage, cooperationStore.setActiveCooperation);
            setActive("editCooperationInExcel");
          }
        }; 
        return (
          <Tooltip title="Legújabb kooperáció betöltése">
            <AutoFixHighIcon className="icon" onClick={(e) => {
              showLoadingWrapper(async () => {
                await loadLatestCoop(e); 
                menuStore.setShowMenu(false); 
              })
            }} sx={{ color: 'green', m: '5px', mr: '10px' }} />
          </Tooltip>
        )
      }
    },
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'participators', headerName: '', hide: true },
    { field: 'cooperations', headerName: '', hide: true },
    { field: 'name', headerName: 'Projektnév', width: 130, renderCell: renderToolTip },
    { field: 'client', headerName: 'Megrendelő neve', width: 130, renderCell: renderToolTip },
    { field: 'place', headerName: 'Helyszín', width: 130, renderCell: renderToolTip },
    { field: 'logo', headerName: 'Logó', width: 100, hide: true  },
    { field: 'logo2', headerName: 'Logó', width: 100, hide: true  },
    { field: 'date_of_start', headerName: 'Kezdeti dátum', width: 110 },
    { field: 'is_archived', headerName: 'Státusz', width: 70,
    renderCell: (params) => {
      let archived = false; 
      params.api
        .getAllColumns()
        .map((c) => {
          if(c.field == "is_archived") archived = params.getValue(params.id, c.field)
        })
      return (
        <>
          {archived == true &&
            <Tooltip title="Archivált">
              <ArchiveIcon className="icon" sx={{ m: '5px' }} />
            </Tooltip>
          }
          {archived == false &&
            <Tooltip title="Nem archivált">
              <UnarchiveIcon className="icon" sx={{ color: 'green', m: '5px' }} />
            </Tooltip>
          }
        </>
      ) 
    }
    },
    {
      field: "edit", headerName: "", width: 125, sortable: false,
      renderCell: (params) => {
        const editSelectedProject = async (e, checkSheets = false) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = await getCurrentRow(params);
          const projectCoops = thisRow.cooperations;
          if (projectCoops.length > 0) {
            let firstCoopDate = projectCoops[0].date;
            let latestCoop = projectCoops[0];
            for (const coop of projectCoops) {
              if (coop.date < firstCoopDate) firstCoopDate = coop.date;
              if (coop.date > latestCoop.date) latestCoop = coop;
            }
            setLatestCooperation(latestCoop);
            setCoopDateLimit(firstCoopDate);
          } else if (coopDateLimit != "2000-01-01"){
            setCoopDateLimit("2000-01-01");
          }
          if (checkSheets) {
            const isOtherSheetLoaded = await checkExistingSheets(thisRow);
            if (!isOtherSheetLoaded) {
              menuStore.setShowMenu(false);
              setActive("CoopDateDialogOpen");
            } 
            return isOtherSheetLoaded;
          } else {
            return undefined; // when we only edit the project and we don't have to check the sheets
          }
        };
        return (
          <>
            <Tooltip title="Projekt szerkesztése">
              <ModeEdit className="icon" onClick={(e) => {editSelectedProject(e); setActive("editProject"); menuStore.setShowMenu(false)}} sx={{ color: 'green', m: '5px', mr: '10px' }} />
            </Tooltip>
            <ConfirmDialogWithBtn
                    title="Figyelem!"
                    description="Az aktív munkafüzet tartalmaz olyan munkalapot, amely nem tartozik a betölteni kívánt projekthez. Biztosan be szeretné tölteni a projekthez tartozó munkalapokat? A meglévő munkalapjai nem vesznek el."
                    yes="Betöltés"
                    no="Mégse"
                    setConfirm={setConfirm}
                    type="fileOpenIcon "
                    openRequired={editSelectedProject}
            />
            <Tooltip title="Kedvencek">
              <Checkbox
                checked={allFavourites.filter(fav => params.id === fav.project && userData.user_id === fav.user).length > 0}
                icon={<StarOutlineIcon/>}
                checkedIcon={<StarIcon color="success"/>}
                onClick={(e) => updateFavourites(params.id)}
              />
            </Tooltip>
          </>
        )
      }
    },
    {
      field: "clone", headerName: "", width: 125, sortable: false,
      renderCell: (params) => {
        const cloneProject = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = await getCurrentRow(params);
          const resp = await requestData(`${URL}/api/projects/${thisRow.id}/clone`, 'POST', {},  addProject, null, dialogStore.showErrorMessage);
          if (resp) {
            dialogStore.showInformMessage(`A projekt klónozása sikeresen megtörtént. Az új projekt neve: ${resp.name}`)
            fetchData(`${URL}/api/cooperations`, setCooperationsData, dialogStore.showErrorMessage);
            fetchData(`${URL}/api/cooperation_members`, setCoopMembersData, dialogStore.showErrorMessage);
          }
        };
        return (
          <>
          <Tooltip title="Projekt klónozása">
            <ContentCopyIcon className="icon" onClick={(e) => {cloneProject(e)}} sx={{ color: 'green', m: '5px', mr: '10px' }} />
            </Tooltip>
          </>
        )
      }
    },
  ];

  const updateFavourites = async (projectId) => {
    if(currFavProjectIds.includes(projectId)) {
      const deletedFavourite = allFavourites.filter(fav => projectId === fav.project && userData.user_id === fav.user)[0];
      const resp = await requestDeleteData(`${URL}/api/favourites/${deletedFavourite.id}/`, 'DELETE', deletedFavourite.id, null, null, dialogStore.showErrorMessage);
      if (resp != 'err') setAllFavourites(allFavourites.filter(fav => fav.id!=deletedFavourite.id && fav.user == userData.user_id));
    } else {
      const newFavourite = {project: projectId, user: userData.user_id}
      const resp = await requestData(`${URL}/api/favourites/`, 'POST', newFavourite,  null, null, dialogStore.showErrorMessage); 
      if (resp != null) {
        setAllFavourites(favs => [...favs, resp]);
      }
    }
  }

  const getCurrentRow = async (params) => {
    const api = params.api;
    const thisRow = {}; 
    const columns = api.getAllColumns().filter((c) => (c.field !== "edit" && c.field !== "loadCoop"));
    for (const c of columns) {
      thisRow[c.field] = params.getValue(params.id, c.field);
    }
    const allCooperations = await fetchData(`${URL}/api/cooperations/?project=${thisRow.id}`, null, dialogStore.showErrorMessage);
    thisRow.cooperations = allCooperations;
    updateProject(thisRow);
    setSelectedProject(thisRow);
    return thisRow;
  }

  const fetchAll = async () => {
    fetchData(`${URL}/api/projects`, setProjectsData, dialogStore.showErrorMessage);
    fetchData(`${URL}/api/persons`, setEmployeesData, dialogStore.showErrorMessage);
    fetchData(`${URL}/api/cooperations`, setCooperationsData, dialogStore.showErrorMessage);
    fetchData(`${URL}/api/cooperation_members`, setCoopMembersData, dialogStore.showErrorMessage);
    fetchData(`${URL}/api/is-user-staff/`, setUserData, dialogStore.showErrorMessage);
    fetchData(`${URL}/api/favourites`, setAllFavourites, dialogStore.showErrorMessage);
  }

  useEffect(() => {
    const currFavourites = allFavourites.filter(fav => fav.user == userData.user_id);
    setCurrFavProjectIds(currFavourites.map(fav => fav.project));
  },[allFavourites, userData])

  useEffect(() => {
    fetchAll();
  },[]);

  useEffect(() => {
    // sorting projects based on their latest cooperation date and favourites
    const currFavProjects = projectsData.filter(project => currFavProjectIds.includes(project.id));
    setOrderedProjects(sortProjects(currFavProjects));
    const notFavProjects = projectsData.filter(project => !currFavProjects.includes(project));
    setOrderedProjects(favProjects => [...favProjects, ...sortProjects(notFavProjects)]);
  },[projectsData, currFavProjectIds]);

  const sortProjects = (projectsToSort) => {
    const sortedProjects = projectsToSort.slice().sort(function(a, b) { 
      return moment(b.date_of_start).format('YYYYMMDD') - moment(a.date_of_start).format('YYYYMMDD');
    })
    return sortedProjects;
  }

    return (
      <main className="ms-welcome__main">
        {active === "allProjects" && 
            <div>
              <h2 style={{paddingTop: '32px'}}>Projektek</h2>
              {userData.is_user_staff == true &&
                <div>
                  <ClickButton variant="outlined" onClick={() => {setActive("newProject"); menuStore.setShowMenu(false)}} sx={{mr: '10px'}}>
                  <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                  Új projekt
                  </ClickButton>  
                  <a target="_blank" href={`${URL}/admin/`} style={{ textDecoration: 'none' }}>
                    <ClickButton variant="outlined">
                    <SupervisedUserCircleIcon className="icon" sx={{ color: 'green', mr: '5px' }}/>
                    Admin oldal
                    </ClickButton> 
                  </a>
                </div>
              }
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  localeText={datagridLocalization()}
                  rows={orderedProjects}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableColumnSelector
                />
              </div>
            </div>
        }
        {active === "newProject" && < AddProject renderParentComp={() => {setActive("allProjects"); menuStore.setShowMenu(true)}} />}
        {active === "editProject" && < EditProject renderParentComp={() => {setActive("allProjects"); menuStore.setShowMenu(true)}} project={selectedProject} setProject={setSelectedProject} isAdmin={userData.is_user_staff} />}
        {active === "CoopDateDialogOpen" && < CoopDateDialog setActive={setActive} latestCoopDate={latestCooperation.date} coopDateLimit={coopDateLimit} setCoopDateLimit={setCoopDateLimit} showErrorMessage={dialogStore.showErrorMessage} project={selectedProject} cooperationStore={cooperationStore}/>}
        {active === "editCooperationInExcel" && < EditCooperation project={selectedProject} participators={selectedProject.participators} renderParentComp={() => {setActive("allProjects"); menuStore.setShowMenu(true)}} cooperation={latestCooperation} inExcel={true} hasCoopLoadedIntoExcelTable={true}/>}
      </main>
    );
}