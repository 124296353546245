import create from 'zustand';

const useDialogStore = create((set) => ({
    errorMessage: "",
    showErrorMessage: (message) => set(state => ({
        errorMessage: message,
        openErrorDialog: true
    })),
    openErrorDialog: false,
    setOpenErrorDialog: (value) => set(state => ({
        openErrorDialog: value
    })),
    informMessage: "",
    showInformMessage: (message) => set(state => ({
        informMessage: message,
        openInformDialog: true
    })),
    openInformDialog: false,
    setOpenInformDialog: (value) => set(state => ({
        openInformDialog: value
    })),
}));

export default useDialogStore;