import create from 'zustand';

const useProjectStore = create((set) => ({
    projectsData: [],
    setProjectsData:  (data) => {
        set({projectsData: data})
    },
    addProject: (newProject) => set(state => ({
        projectsData: [...state.projectsData, newProject]
    })),
    updateProject: (updatedProject) => set(state => ({
        projectsData: state.projectsData.map((project) => {
            if(project.id == updatedProject.id) {
                return updatedProject;
            } else {
                return project;
            }
        })
    })),
    removeProject: (id) =>set(state => ({
        projectsData: state.projectsData.filter((project) => project.id !== id)
    }))
       
}));

export default useProjectStore;