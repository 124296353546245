import * as React from "react";
import moment from 'moment';
import 'moment/locale/hu';
import TextField from '@mui/material/TextField';
import { ClickButton, BasicButton } from "./styles/ButtonStyles";
import { useState, useEffect } from "react";
import useCoopMemberStore from "./stores/useCoopMemberStore";
import EmployeeTable from "./EmployeeTable";
import TopicAndEntryInsertBtns from "./TopicAndEntryInsertBtns";
import useEmployeeStore from "./stores/useEmployeeStore";
import useCooperationStore from "./stores/useCooperationStore";
import useDialogStore from "./stores/useDialogStore";
import { requestFileData } from "./Utils";
import { URL } from "../utils/constans";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { showLoadingWrapper, synchroniseCooperation } from "../utils/syncronisation_utils";
import MailTo from "./MailTo";
import { getFile, downloadFile, getFileNameFromUrl } from "../utils/image_utils";

// icons
import PeopleIcon from '@mui/icons-material/People';
import ModeEdit from '@mui/icons-material/ModeEdit';
import AddCircle from '@mui/icons-material/AddCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteIcon from '@mui/icons-material/Delete';
import useProjectStore from "./stores/useProjectStore";

export default function EditCooperation({project, participators, renderParentComp, cooperation, inExcel = false, hasCoopLoadedIntoExcelTable = false}) {
    const [active, setActive] = useState("cooperationDetails")
    const [name, setName] = useState(cooperation.name);
    const [date, setDate] = useState(cooperation.date);
    const [url, setUrl] = useState(cooperation.attachments_url ? cooperation.attachments_url : "");
    const [place, setPlace] = useState(cooperation.place ? cooperation.place : "");
    const [attachment, setAttachment] = useState(cooperation.attachment);
    const [coopId, setCoopId] = useState(cooperation.id);
    const [coopMembers, setCoopMembers] = useState([]);
    const [projectParticipators, setprojectParticipators] = useState([]);
    const [orderNum, setOrderNum] = useState(null);
    
    const employeesData = useEmployeeStore((state) => state.employeesData);
    const coopMembersData = useCoopMemberStore((state) => state.coopMembersData);
    const dialogStore = useDialogStore();
    const cooperationStore = useCooperationStore();
    const projectStore = useProjectStore();   

    const updateCooperation = async () => {
        if (name != "") {
            const formData = new FormData();
            formData.append("project", project.id);
            formData.append("name", name);
            formData.append("date", date);
            formData.append("attachments_url", url);
            formData.append("place", place);
            if (attachment != null && (attachment.name || attachment == "")) formData.append("attachment", attachment);

            const resp = await requestFileData(`${URL}/api/cooperations/${coopId}/`, 'PUT', formData,  cooperationStore.updateCooperation, inExcel == true ? null : renderParentComp, dialogStore.showErrorMessage);
            if (resp !== "err") {
                const projectToUpdate = projectStore.projectsData.find(p => p.id === project.id);
                projectToUpdate.cooperations = projectToUpdate.cooperations.map(coop => {
                    if (resp.id === coop.id) return resp;
                    else return coop;
                });
                projectStore.updateProject(projectToUpdate);
                cooperationStore.setActiveCooperation(null);
            }
        } else {
            dialogStore.showErrorMessage("A kooperáció nevét kötelező megadni.");
        }
    }

    useEffect(() => {
        if (cooperationStore.activeCooperation != null) {
            const newCoop = cooperationStore.activeCooperation;
            setName(newCoop.name);
            setDate(newCoop.date);
            setPlace(newCoop.place ? newCoop.place : "");
            setUrl(newCoop.url ? newCoop.url : "");
            setAttachment(newCoop.attachment);
            setCoopId(newCoop.id);
            setOrderNum(newCoop.orderNum)
        }
    },[cooperationStore.activeCooperation])

    useEffect(() => {
        setMembers();
    },[coopMembersData, coopId])

    const setMembers = () => {
        let members = coopMembersData.filter(coopMem => coopMem.cooperation == coopId);
        let value = [];
        let newObject;
        members.forEach(memb => {
            newObject= employeesData.find(employee => memb.person == employee.id);
            value.push({...newObject, coopMembId: memb.id})
        })
        setCoopMembers(value);

        let emp = [];
        participators.forEach(participator => {
            emp.push(employeesData.find(emp => emp.id == participator));
        })
        setprojectParticipators(emp);
    }

    const saveCooperation = async () => {
        showLoadingWrapper(async () => {
            if (hasCoopLoadedIntoExcelTable) {
                await synchroniseCooperation();
            } else {
                await updateCooperation(); 
            }
        })
    }

    const uploadhandler = (event) => {
        const file = event.target.files[0];
        setAttachment(file);
    }

    return (
        <main>
            {active === "cooperationDetails" &&
                <div>
                    <KeyboardBackspaceIcon className="icon" onClick={() => {cooperationStore.setActiveCooperation(null); renderParentComp()}} sx={{ color: 'green', fontSize: '30px', cursor: 'pointer' }}/>
                    <h2>Kooperáció szerkesztése</h2>
                    <div style={{ display: 'block', paddingBottom: '10px' }}>
                        {hasCoopLoadedIntoExcelTable == true &&
                            <MailTo coopId={coopId} orderNum={orderNum} project={project}/>
                        }
                        <BasicButton color="success" variant="contained" onClick={() => saveCooperation()} disabled={cooperationStore.disableSyncronisationBtn && hasCoopLoadedIntoExcelTable}>Mentés</BasicButton>
                        <ClickButton variant="outlined" onClick={() => setActive("employeeTable")}>
                            <PeopleIcon className="icon" sx={{ color: 'green', mr: '5px' }}></PeopleIcon>
                            Kooperációrésztvevők
                        </ClickButton>
                    </div>
                    { inExcel == true && cooperationStore.showInsertBtns == true &&
                        <TopicAndEntryInsertBtns />
                    }
                    <div sx={{ display: 'block' }}>
                        <TextField id="1" disabled={hasCoopLoadedIntoExcelTable} value={name} onChange={(e) => setName(e.target.value)} label="Név" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                locale="hu"
                                id="2"
                                disabled={hasCoopLoadedIntoExcelTable}
                                label="Dátum"
                                inputFormat="YYYY-MM-DD"
                                toolbarFormat="MMMM DD"
                                value={date}
                                onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>}
                                variant="outlined"
                            />
                        </LocalizationProvider>
                        <TextField id="3" disabled={hasCoopLoadedIntoExcelTable} value={place} onChange={(e) => setPlace(e.target.value)} label="Helyszín" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                        <TextField id="4" disabled={hasCoopLoadedIntoExcelTable} value={url} onChange={(e) => setUrl(e.target.value)} label="Kooperációs mappa link" variant="outlined" color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>
                        <div className="file-uploader">
                            {(attachment == "" || attachment == null) &&
                                <ClickButton disabled={hasCoopLoadedIntoExcelTable}>
                                    <AddCircle className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                    Jelenléti ív hozzáadása
                                    <input id="5" onChange={uploadhandler} type="file" accept="image/*" variant="outlined" color="success" />
                                </ClickButton>
                            }
                            {attachment != null && attachment != "" &&
                                <>
                                    <ClickButton disabled={hasCoopLoadedIntoExcelTable}>
                                        <ModeEdit className="icon" sx={{ color: 'green', mr: '5px' }}/>
                                        Jelenléti ív cseréje
                                        <input id="6" onChange={uploadhandler} type="file" accept="image/*" variant="outlined" color="success" />
                                    </ClickButton>
                                    {!attachment.name &&
                                        <>
                                            <ClickButton onClick={
                                                (e)=>
                                                    getFile(attachment, {blob: true, img: false}).then(
                                                            (blobRes)=>{
                                                                downloadFile(blobRes, getFileNameFromUrl(attachment))
                                                            }
                                                        )
                                                }>
                                                Jelenléti ív letöltése
                                            </ClickButton>
                                            { !hasCoopLoadedIntoExcelTable &&
                                                <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={() => setAttachment("")}/>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {attachment != null && attachment.name &&
                                <>
                                    <span>{attachment.name}</span>
                                    {!hasCoopLoadedIntoExcelTable &&
                                        <DeleteIcon color="warning" variant="contained" sx={{ml: '5px'}} onClick={() => setAttachment("")}/>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
            {active === "employeeTable" &&
                <div>
                    <EmployeeTable renderParentComp={() => setActive("cooperationDetails")} tableEmp={coopMembers} setCoopMembers={setCoopMembers} multiple={false} projectParticipators={projectParticipators} coop_id={coopId} title="Vállalkozó/személy kiválasztása a kooperációhoz" isShowed={!hasCoopLoadedIntoExcelTable}/>
                </div>
            }
        </main>
    )
}
