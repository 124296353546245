import {
  GET_PARENT_ROW_NUMBER_FX,
  GET_PARENTS_ID_FX,
  HAS_NO_PARENT,
  RELATION_EXCEL_FX,
  GET_CURRENT_ROW_NO_FX,
  OPEN,
  CLOSED,
  HAS_ELOG,
  HAS_NO_ELOG,
  EMPTY,
  ENTRY_TYPES,
  WAS_MODIFIED,
} from "./constans";

const footerCells = [
  ["Jelmagyarázat:", EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
  [EMPTY, "Az aktuális bejegyzések kékkel vannak írva,", EMPTY, EMPTY, EMPTY, "Lezárult (0)", 0],
  [
    EMPTY,
    "a határidők és kritikus folyamatok pirossal.",
    EMPTY,
    EMPTY,
    EMPTY,
    "Kritikus téma, jelentős határidő vagy költség kockázat (1)",
    1,
  ],
  [EMPTY, "F = Feladat, D = Döntés, I  = Információ", EMPTY, EMPTY, EMPTY, "Nyitott, kockázatokat hordozó téma (2)", 2],
  [EMPTY, "ASAP = Minél hamarabb, sürgősen intézendő", EMPTY, EMPTY, EMPTY, "Folyamatban, normál besorolású (3)", 3],
  [EMPTY, "foly = folyamatosan figyelembe veendő", EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
];

const getHeaderCells = ({ project, cooperation }) => {
  let coopNumber;
  project.cooperations.find((coop, i) => {
    if (coop.id == cooperation.id) coopNumber = i + 1;
  });
  return [
    [EMPTY, EMPTY, project.name, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, cooperation.id],
    [EMPTY, EMPTY, cooperation.name, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    [EMPTY, EMPTY, `${coopNumber}. Kooperációs jegyzőkönyv`, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    ["Helyszín:", `${project.place}`, EMPTY, "Dátum:", `${cooperation.date}`, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    ["Nr.", "Dátum", "Bejegyzés", "Felelős", "Határidő", "Státusz", EMPTY, EMPTY, "E-napló", "Csatolmányok"],
  ];
};

const getFooterCells = () => {
  return footerCells;
};

const getTopicCells = (topic, is_main) => {
  return [
    topic.abbreviation,
    EMPTY,
    topic.name,
    EMPTY,
    EMPTY,
    EMPTY,
    EMPTY,
    `${topic.is_closed ? CLOSED : OPEN}`,
    EMPTY,
    EMPTY,
    topic.wasModified ? WAS_MODIFIED : EMPTY,
    `${is_main ? "MT" : "ST"}`,
    topic.id,
    topic.version_number,
    topic.parentId ? topic.parentId : GET_PARENTS_ID_FX,
    topic.parentRowNo ? topic.parentRowNo : GET_PARENT_ROW_NUMBER_FX,
    RELATION_EXCEL_FX,
    GET_CURRENT_ROW_NO_FX,
    getParentsInheritanceAbbreviation(topic.abbreviation),
  ];
};

const getEntryCells = (entry, is_main) => {
  //console.log("entry", entry);
  const deadline = entry.is_asap ? "ASAP" : entry.is_continuous ? "folyt." : entry.deadline;
  const type = isNaN(entry.type) ? entry.type : ENTRY_TYPES[entry.type];
  return [
    entry.entry_number,
    entry.date,
    entry.description,
    entry.responsible,
    deadline,
    type,
    entry.status,
    `${entry.is_closed ? CLOSED : OPEN}`,
    `${entry.is_enaplo ? HAS_ELOG : HAS_NO_ELOG}`,
    `${entry.attachment ? `=HYPERLINK("${entry.attachment}")` : ""}`,
    entry.wasModified ? WAS_MODIFIED : EMPTY,
    `${is_main ? "ME" : "SE"}`,
    entry.id,
    entry.version_number,
    entry.parentId ? entry.parentId : GET_PARENTS_ID_FX,
    entry.parentRowNo ? entry.parentRowNo : GET_PARENT_ROW_NUMBER_FX,
    RELATION_EXCEL_FX,
    GET_CURRENT_ROW_NO_FX,
    getParentsInheritanceAbbreviation(entry.entry_number),
  ];
};

const getCorrectTypeCells = (data) => {
  return data.dataType === "E"
    ? getEntryCells(data, !data.main_entry)
    : getTopicCells(data, !(data.main_topic || data.has_main_topic));
};

const convertCooperationDataToCells = (cooperation) => {
  const main_topics = cooperation.topics.filter((topic) => topic.main_topic == null);
  return deepConvertTopicsToCells(main_topics, true);
};

const deepConvertTopicsToCells = (topics, is_main = false) => {
  let rows = [];
  topics.forEach((topic) => {
    rows.push(getTopicCells(topic, is_main));
    rows.push(...deepConvertEntriesToCells(topic.entries));
    rows.push(...deepConvertTopicsToCells(topic.sub_topics, false));
  });
  return rows;
};

const deepConvertEntriesToCells = (entries, is_main = false) => {
  let rows = [];
  entries.forEach((entry) => {
    rows.push(getEntryCells(entry, is_main));
    rows.push(...deepConvertEntriesToCells(entry.sub_entries));
  });
  return rows;
};

const formatColumns = (sheet) => {
  const HIDDEN_DATA_COLUMN_WIDTH = 0;
  sheet.getRange("L:L").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("K:K").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("M:M").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("N:N").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("O:O").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("P:P").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("Q:Q").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("R:R").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  sheet.getRange("S:S").format.columnWidth = HIDDEN_DATA_COLUMN_WIDTH;
  
  // Format as text.
  sheet.getRange("A:A").numberFormat = "@";
  sheet.getRange("S:S").numberFormat = "@";
  sheet.getRange("B:B").numberFormat = "yyyy.mm.dd.";
  sheet.getRange("E:E").numberFormat = "yyyy.mm.dd.";
};

const getParentsInheritanceAbbreviation = (entry_number) => {
  if (!entry_number.includes(".")) return HAS_NO_PARENT;

  return entry_number.split(".").slice(0, -1).join(".");
};

export {
  getHeaderCells,
  getFooterCells,
  formatColumns,
  convertCooperationDataToCells,
  deepConvertTopicsToCells,
  deepConvertEntriesToCells,
  getCorrectTypeCells,
};
