import create from 'zustand';

const useCoopMemberStore = create((set) => ({
    coopMembersData: [],
    setCoopMembersData: (data) => {
        set({coopMembersData: data})
    },
    addCoopMember: (newCoopMember) => set(state => ({
        coopMembersData: [...state.coopMembersData, newCoopMember]
    })),
    removeCoopMember: (id) =>set(state => ({
        coopMembersData: state.coopMembersData.filter((coopMember) => coopMember.id !== id)
    }))
       
}));

export default useCoopMemberStore;