import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BasicButton } from "./styles/ButtonStyles";
import useEmployeeStore from "./stores/useEmployeeStore";
import { URL } from '../utils/constans';
import useDialogStore from "./stores/useDialogStore";
import useCoopMemberStore from "./stores/useCoopMemberStore";
import { requestData } from './Utils';

export default function FormDialog({updateEmployees, setTableEmployees, selectedEmployee, setSelectedEmployee, coop_id}) {
  const [open, setOpen] = useState(false);
  const [newJobTitle, setNewJobTitle] = useState(selectedEmployee ? selectedEmployee.job_title : "");
  const [startUpdate, setStartUpdate] = useState(false);
  const employeeStore = useEmployeeStore();
  const dialogStore = useDialogStore();
  const addCoopMember = useCoopMemberStore((state) => state.addCoopMember);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStartUpdate(true);
  };

  useEffect(() => {
      if(startUpdate) updateEmployees();
  },[startUpdate])

  const newCooperation = async () => {
    const cooperation_member = {person: selectedEmployee.id, cooperation: coop_id, cooperation_role: newJobTitle ? newJobTitle : selectedEmployee.job_title};
    const resp = await requestData(`${URL}/api/cooperation_members/`, 'POST', cooperation_member, addCoopMember, handleClose, dialogStore.showErrorMessage);
    if (resp != null) {
      setTableEmployees(tableEmployees => [...tableEmployees, {...selectedEmployee, coopMembId: resp.id}]);
      setSelectedEmployee(null)
    }
  }

  const updateEmployee = async () => {
    const resp = await requestData(`${URL}/api/persons/${selectedEmployee.id}/`, 'PUT', selectedEmployee, employeeStore.updateEmployee, newCooperation, dialogStore.showErrorMessage);
    if (resp != null) {
      setSelectedEmployee(null)
    }
  }

  const changeJobTitle = () => {
    selectedEmployee.job_title = newJobTitle;
    updateEmployee();
  }

  return (
    <>
      <BasicButton color="success" variant="contained" sx={{verticalAlign: 'bottom'}} disabled={selectedEmployee === null || selectedEmployee.length === 0} onClick={handleClickOpen}>Hozzáadás</BasicButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{textAlign:'center'}}>Vállalkozó/személy munkaköre</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedEmployee && 
              <>
                <span>Szeretné {selectedEmployee.name} munkakörét megváltoztatni?</span>
                <span style={{display: "block"}}>Jelenlegi munkaköre: {selectedEmployee.job_title}</span>
              </>
            }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="új munkakör megadása"
            type="email"
            fullWidth
            variant="standard"
            color="success"
            onChange={(e) => setNewJobTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {newCooperation()}} color="success"  variant="contained" sx={{m:'16px'}}>Nem változtatom meg</Button>
          <Button onClick={() => {changeJobTitle()}} color="success"  variant="contained" sx={{m:'16px'}}>Új munkakör mentése</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
