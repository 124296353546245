import {
  excelWrapper,
  insertData,
  HEADER_CELLS_HEIGHT,
  disableEventsWrapper,
} from "./excel_utils";

import {
  EMPTY,
  RELATION_STRING_COLUMN_INDEX,
  TYPE_STR_COLUMN_INDEX,
  WAS_MODIFIED,
  RELATION_EXCEL_FX,
  GET_PARENTS_ID_FX,
  HAS_NO_PARENT,
  GET_CURRENT_ROW_NO_FX,
  PARENT_COLUMN_LETTER,
  WAS_MODIFIED_COLUMN_INDEX,
  HAS_NO_ELOG,
} from "./constans";

import { readExcelEntry, readExcelTopic, showLoadingWrapper } from "./syncronisation_utils";

const readExcelData = async (context, sheet, detailed = false) => {
  let excelData = [];

  const usedRange = sheet.getUsedRange();
  usedRange.load("values, rowCount");
  await context.sync();

  for (var row = usedRange.rowCount - 1; row > 0; row--) {
    const currentRow = usedRange.values[row];

    const typeStr = currentRow[TYPE_STR_COLUMN_INDEX];
    const dataType = typeStr === "ME" || typeStr === "SE" ? "E" : typeStr === "MT" || typeStr === "ST" ? "T" : null;
    if (dataType === null) continue;

    if (detailed) {
      excelData.push({
        ...(dataType === "T" ? readExcelTopic(currentRow) : readExcelEntry(currentRow)),
        wasModified: currentRow[WAS_MODIFIED_COLUMN_INDEX] === WAS_MODIFIED,
      });
    } else {
      excelData.push({
        rowIndex: row,
        relationString: currentRow[RELATION_STRING_COLUMN_INDEX],
        dataType,
      });
    }
  }

  return excelData;
};

const insertNewTopicOnThisLevel = async () => {
  await insertButtonWrapper(async (context) => {
    const selectedRow = context.workbook.getSelectedRange();

    selectedRow.load("rowIndex");
    await context.sync();

    const selectedWorksheet = selectedRow.worksheet;
    const selectedRowIndex = selectedRow.rowIndex;

    var selectedRowParentColumnCell = selectedWorksheet.getRange(`${PARENT_COLUMN_LETTER}${selectedRowIndex + 1}`);

    selectedRowParentColumnCell.load("values");
    await context.sync();

    var parentRowNumberToFind = selectedRowParentColumnCell.values[0][0];

    await insertNewTopic(context, selectedWorksheet, parentRowNumberToFind);
  });
};

const insertNewTopicUnderThisLevel = async () => {
  await insertButtonWrapper(async (context) => {
    const selectedRow = context.workbook.getSelectedRange();

    selectedRow.load("address, rowIndex");
    await context.sync();

    const selectedWorksheet = selectedRow.worksheet;
    const selectedRowIndex = selectedRow.rowIndex;
    var parentRowNumberToFind = selectedRowIndex + 1;

    await insertNewTopic(context, selectedWorksheet, parentRowNumberToFind);
  });
};

const insertNewTopic = async (context, selectedWorksheet, parentRowNumberToFind) => {
  const excelData = await readExcelData(context, selectedWorksheet);
  // Be able to add Main Topics to an empty Cooperation sheet.
  const { insertAfter, isMain } = getTopicInsertAfterRow(excelData, parentRowNumberToFind);

  await insertData(true, context, selectedWorksheet, "A", insertAfter + 1, [
    [
      EMPTY,
      EMPTY,
      isMain ? "Főtémakör neve" : "Altémakör neve",
      EMPTY,
      EMPTY,
      EMPTY,
      EMPTY,
      "o",
      EMPTY,
      EMPTY,
      WAS_MODIFIED,
      isMain ? "MT" : "ST",
      EMPTY,
      EMPTY,
      EMPTY,
      parentRowNumberToFind ? parentRowNumberToFind : HAS_NO_PARENT,
      RELATION_EXCEL_FX,
      GET_CURRENT_ROW_NO_FX,
    ],
  ]);

  // await addNewGrouping(selectedWorksheet, context, parentRowNumberToFind, insertAfter + 1);
};

const insertNewEntryUnderThisLevel = async () => {
  await insertButtonWrapper(async (context) => {
    const selectedRow = context.workbook.getSelectedRange();

    selectedRow.load("address, rowIndex");
    await context.sync();

    const selectedWorksheet = selectedRow.worksheet;
    const selectedRowIndex = selectedRow.rowIndex;
    var parentRowNumberToFind = selectedRowIndex + 1;

    await insertNewEntry(context, selectedWorksheet, parentRowNumberToFind, true);
  });
};

const insertNewEntryOnThisLevel = async () => {
  await insertButtonWrapper(async (context) => {
    const selectedRow = context.workbook.getSelectedRange();

    selectedRow.load("rowIndex");
    await context.sync();

    const selectedWorksheet = selectedRow.worksheet;
    const selectedRowIndex = selectedRow.rowIndex;

    var selectedRowParentColumnCell = selectedWorksheet.getRange(`${PARENT_COLUMN_LETTER}${selectedRowIndex + 1}`);

    selectedRowParentColumnCell.load("values");
    await context.sync();

    var parentRowNumberToFind = selectedRowParentColumnCell.values[0][0];

    // Don't allow to add Entry on Main Topic level.
    if (parentRowNumberToFind === HAS_NO_PARENT) return;
    await insertNewEntry(context, selectedWorksheet, parentRowNumberToFind, false);
  });
};

const insertNewEntry = async (context, selectedWorksheet, parentRowNumberToFind, insertUnder) => {
  const excelData = await readExcelData(context, selectedWorksheet);
  const insertAfter = getEntryInsertAfterRow(excelData, parentRowNumberToFind, insertUnder).insertAfter;
  selectedWorksheet.load("name");
  await context.sync();
  const currentCoopDate = selectedWorksheet.name;
  await insertData(true, context, selectedWorksheet, "A", insertAfter, [
    [
      EMPTY,
      currentCoopDate,
      "Bejegyzés neve",
      EMPTY,
      "ASAP",
      "F",
      3,
      "o",
      HAS_NO_ELOG,
      EMPTY,
      WAS_MODIFIED,
      "SE",
      EMPTY,
      EMPTY,
      EMPTY,
      parentRowNumberToFind,
      RELATION_EXCEL_FX,
      GET_CURRENT_ROW_NO_FX,
    ],
  ]);

  await addNewGrouping(selectedWorksheet, context, parentRowNumberToFind, insertAfter);
};

const addNewGrouping = async (selectedWorksheet, context, parentRowNumberToFind, childRow) => {
  if (parentRowNumberToFind) {
    if (childRow - parentRowNumberToFind === 1) {
      // checking whether this child is the first child of the parent
      selectedWorksheet.getRange(`${childRow}:${childRow}`).group(Excel.GroupOption.byRows);
      await context.sync();
    }
  }
};

const getTopicInsertAfterRow = (excelData, parentRowNumberToFind) => {
  var insertAfter;
  var insertAfterIndex;
  var isMain = false;

  if (excelData.length === 0) {
    insertAfter = HEADER_CELLS_HEIGHT;
    console.log("Nulla: ", HEADER_CELLS_HEIGHT);
    parentRowNumberToFind = HAS_NO_PARENT;
    isMain = true;
  } else {
    console.log("excelData: ", excelData);
    if (parentRowNumberToFind === HAS_NO_PARENT) {
      insertAfter = excelData[0].rowIndex + 1 ? excelData[0].rowIndex + 1 : excelData[0].rowNo;

      isMain = true;
      return { insertAfter, isMain };
    } else {
      // Start going trough lines of excelData, until you find one that is of the
      // same inheritance.
      for (let rowIndex in excelData) {
        const currentRow = excelData[rowIndex];
        const relationArr = currentRow.relationString.split(".");
        const relationStringToSearch = relationArr.slice(0, -1).join(".") + ".";
        const currentRowParentRowNumber = relationArr.length > 1 ? relationArr[relationArr.length - 2] : HAS_NO_PARENT;

        // Skip over it, if it's not a direct children of -TOPIC- type.
        if (
          currentRowParentRowNumber != parentRowNumberToFind ||
          currentRow.dataType !== "T" ||
          currentRow.relationString.replace(relationStringToSearch, "").split(".").length !== 1
        )
          continue;

        insertAfterIndex = rowIndex;
        console.log("Egy: ", insertAfterIndex);
        while (insertAfterIndex >= 0) {
          const currentInsertAfter = excelData[insertAfterIndex];
          const currentRelationArr = currentInsertAfter.relationString.split(".");
          if (
            !currentRelationArr.includes(parentRowNumberToFind.toString()) ||
            (currentRelationArr.dataType === "E" &&
              currentRelationArr[currentRelationArr.length - 2] == parentRowNumberToFind)
          ) {
            break;
          }
          insertAfterIndex--;
        }
        insertAfter = insertAfterIndex === -1 ? excelData[0].rowIndex + 1 : excelData[insertAfterIndex].rowIndex;
        break;
      }

      if (!insertAfter) insertAfter = parentRowNumberToFind;
    }
  }
  console.log("insertAfter: ", insertAfter);
  return { insertAfter, isMain };
};

const getEntryInsertAfterRow = (excelData, parentRowNumberToFind, insertUnder) => {
  var insertAfterIndex;
  var insertAfter;
  // Start going trough lines of excelData, until you find one that is of the
  // same inheritance.
  console.log("excelData", excelData);
  for (let rowIndex in excelData) {
    const currentRow = excelData[rowIndex];
    const relationArr = currentRow.relationString.split(".");
    const currentRowParentRowNumber = relationArr.length > 1 ? relationArr[relationArr.length - 2] : HAS_NO_PARENT;
    const relationStringToSearch = relationArr.slice(0, -1).join(".") + ".";

    // Skip over it, if it's not a direct children.
    if (
      currentRowParentRowNumber != parentRowNumberToFind ||
      currentRow.relationString.replace(relationStringToSearch, "").split(".").length !== 1
    )
      continue;

    insertAfterIndex = rowIndex;

    // Start stepping backwards, until either finding the first Topic or Entry
    //that is not related to this branch, or there is no more data in excelData.
    while (true) {
      insertAfterIndex--;
      if (!excelData[insertAfterIndex]) break;
      if (!excelData[insertAfterIndex].relationString.startsWith(relationStringToSearch)) break;
    }

    // If there is no more data in excelData, that means that the new row should be inserted after the current last row.
    insertAfter = insertAfterIndex === -1 ? excelData[0].rowIndex + 2 : excelData[insertAfterIndex].rowIndex + 1;
    break;
  }

  if (!insertAfter) {
    insertAfter = insertUnder ? parentRowNumberToFind + 1 : parentRowNumberToFind;
  }
  return { insertAfter };
};

const insertButtonWrapper = async (fn) => {
  await excelWrapper(async (context) => {
    await disableEventsWrapper(context, async () => {
      await showLoadingWrapper(async () => {
        await fn(context);
      });
    });
  });
};

export {
  getTopicInsertAfterRow,
  getEntryInsertAfterRow,
  readExcelData,
  insertNewTopicOnThisLevel,
  insertNewTopicUnderThisLevel,
  insertNewEntryUnderThisLevel,
  insertNewEntryOnThisLevel,
};
