import * as React from 'react';
import { BasicButton } from "./styles/ButtonStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ErrorDialog({description, setOpen}) {

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
        <ErrorOutlineIcon sx={{fontSize: 60}} color="warning"/>
        <div sx={{fontSize: '20px'}}>
            Hiba!
        </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="content" dangerouslySetInnerHTML={{__html: description}}></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <BasicButton onClick={() => setOpen(false)} color="success" variant="contained">Rendben</BasicButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
