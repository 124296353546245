import { getHeaderCells, getFooterCells, formatColumns, convertCooperationDataToCells } from "./formatting_utils";
import { getSyncedData, handleDataChange, syncExcelWithServer } from "./syncronisation_utils";
import { getFile, cutBase64 } from "./image_utils";
import moment from "moment";
import {
  HEADER_CELLS_HEIGHT,
  ALPHABET,
  WAS_MODIFIED_COLUMN_LETTER,
  WAS_MODIFIED,
  ERROR_COLUMN_LETTER,
  OPEN,
  CLOSED,
  HAS_ELOG,
  HAS_NO_ELOG,
  PROJECT_STAGE,
  NEW_SHEET_ROWCOUNT,
  HEADER_COOPERATION_ID_CELL,
  ID_COLUMN_LETTER,
  RELATION_STRING_COLUMN_LETTER,
  RELATION_STRING_COLUMN_INDEX,
  ID_COLUMN_INDEX,
  EMPTY,
  FILTER_ROW,
  HAS_NO_PARENT,
  PARENT_ROW_NO_COLUMN_LETTER,
  IS_CLOSED_COLUMN_INDEX,
} from "./constans";
import { readExcelData } from "./manual_insert";
import { getCookie } from "../components/Utils";

const FOOTER_CELLS_HEIGHT = 6;
const TABLE_OF_CONTENTS_HEADER_HEIGHT = 3;
const NEW_ENTRY_COLOR = "#3333ff";
const CRITICAL_ENTRY_COLOR = "#c0504d";
const DARK_GRAY_COLOR = "#a6a6a6";
const LIGHT_GRAY_COLOR = "#d9d9d9";
const LIGHT_GREEN_COLOR = "#e2efda";
const LIGHT_RED_COLOR = "#ffd1d1";
const LOGO_MAX_HEIGHT = 70;
const LOGO_MAX_WIDTH = 150;
const PIXEL_TO_POINT_CONVERSION = 1.333;
const LOGO_RIGHT_POSITION = 1022 / PIXEL_TO_POINT_CONVERSION;
const HEADER_ROW_END = 5;
const MAX_COLUMN_NUM = 10;
const DEADLINE_COL_NUM = 5;
const TYPE_COL_NUM = 6;
const STATUS_CLOSING_COL_NUM = 8;
const ELOG_COL_NUM = 9;

const NR_COL = "A";
const DATE_COL = "B";
const ENTRY_COL = "C";
const DEADLINE_COL = "E";
const TYPE_COL = "F";
const SIGNAL_COL = "G";
const STATUS_CLOSING_COL = "H";
const ELOG_COL = "I";
const ATTACHMENT_COL = "J";
const STAR_COL = "K";
const MAIN_SUB_COL = "L";
const NUMLIST_COL = "Q";

let sheetChangeEventListener;

const checkExistingSheets = async (project) => {
  let isOtherSheetLoaded = false;

  await excelWrapper(async (context) => {
    let sheets = context.workbook.worksheets;
    sheets.load("items/name");
    await context.sync();

    const existingSheetNames = sheets.items.map((sheet) => sheet.name);
    const existingSheets = sheets.items.map((sheet) => sheet);

    if (existingSheets.length == 1) {
      const firstSheet = context.workbook.worksheets.getItem(existingSheetNames[0]);
      const usedRows = firstSheet.getUsedRange();
      usedRows.load("rowCount");
      await context.sync();
      if (usedRows.rowCount !== 1) isOtherSheetLoaded = true;
    } else {
      for (const sheet of existingSheets) {
        const projectNameCellA1 = sheet.getRange("A1:A1");
        const projectNameCellC1 = sheet.getRange("C1:C1");
        const usedRows = sheet.getUsedRange();
        usedRows.load("rowCount");
        projectNameCellA1.load("text");
        projectNameCellC1.load("text");
        await context.sync();

        const projectNameA1Txt = projectNameCellA1.text[0].join();
        const projectNameC1Txt = projectNameCellC1.text[0].join();
        if (projectNameA1Txt !== project.name && projectNameC1Txt !== project.name && usedRows.rowCount !== 1) {
          isOtherSheetLoaded = true;
        }
      }
    }
  });
  return isOtherSheetLoaded;
};

// Remove all sheets, and load cooperations.
const loadCooperationsIntoExcel = async (project, cooperationStore, latestCoopDate, coopDateLimit, showErrorMessage, setActiveCooperation) => {
  console.time("loadCooperationsIntoExcel");
  await excelWrapper(async (context) => {
    Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged);
    if (!project.id) return;

    const filteredCooperations = project.cooperations.filter(
      (coop) => moment(coop.date).format("YYYYMMDD") >= moment(coopDateLimit).format("YYYYMMDD")
    );
    let sheets = context.workbook.worksheets;

    sheets.load("items/name");
    await context.sync();

    const existingSheets = sheets.items.map((sheet) => sheet.name);
    console.log("existingSheets", existingSheets);
    console.time("downloading images");
    const logoPromise = getFile(project.logo, { blob: false, img: true }, showErrorMessage);
    const logo2Promise = getFile(project.logo2, { blob: false, img: true }, showErrorMessage);
    // Loading can be be even faster if adding sheets runs along with downloading images promise.

    const logoFiles = await new Promise((resolve, reject) => {
      Promise.all([logoPromise, logo2Promise]).then(async (results) => {
        let logos = results.map((result) => {
          if (result === null || result.status === "rejected") {
            console.error("Error when trying to load logo, or logo simply does not exist.");
            console.error(result);
            return null;
          }
          return result;
        });
        resolve(logos);
        console.timeEnd("downloading images");
      });
    });
    console.log("logoFiles: ", logoFiles);

    let latestCoopShouldBeUpdated = null;
    let index = 0;

    if (filteredCooperations.length === 0) {
      throw "A projekthez nem tartozik kooperáció, így nincs mit betölteni.";
    }

    for (const cooperation of filteredCooperations.reverse()) {
      // Add sheet for cooperation if it doesn't already exist.
      let currentSheet;
      const isNew = !existingSheets.includes(cooperation.date);
      if (latestCoopShouldBeUpdated === null) latestCoopShouldBeUpdated = !isNew;
      if (isNew) {
        currentSheet = await addSheet(context, cooperation.date);
        console.log("currentSheet", currentSheet);
      } else {
        currentSheet = context.workbook.worksheets.getItem(cooperation.date);
      }
      currentSheet.position = index;
      await context.sync();

      if (isNew) {
        await loadSheetContent(context, currentSheet, project, cooperation, logoFiles);
      }
      index++;
    }

    // set the active sheet
    let activeCoop = context.workbook.worksheets.getItem(latestCoopDate);
    activeCoop.activate();
    activeCoop.load("items/name");
    await context.sync();

    // get the active cell
    let prevCell = { cellRow: 0, cellColumn: 0, address: '', initialTypeValue: null, initialStatusValue: null };
    showSelectedCell(project, logoFiles, filteredCooperations, cooperationStore, prevCell, setActiveCooperation)
    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, () =>
      showSelectedCell(project, logoFiles, filteredCooperations, cooperationStore, prevCell, setActiveCooperation)
    );

    // Synchronise data from server to the user's sheet.
    const sheetToSync = context.workbook.worksheets.getFirst();

    // Turn off eventListener temporarily,
    // as to not mark the entirity of the latest cooperation sheet.
    if (sheetChangeEventListener) {
      console.log("trying to remvove sheetChangeEventListener");
      await Excel.run(sheetChangeEventListener.context, async (context) => {
        try {
          sheetChangeEventListener.remove();
          await context.sync();
          console.log("sheetChangeEventListener removed");
        } catch {
          console.info("Sheet was deleted before eventlistener was removed. Proceeding...");
        }
      });
    }

    try {
      // Synchronize local Excel data with server data.
      if (latestCoopShouldBeUpdated === true) {
        await syncExcelWithServer(context, sheetToSync, filteredCooperations[0]);
        await context.sync();
      }
    } finally {
      // Add the eventlistener to look for changes in the user's sheet.
      sheetChangeEventListener = sheetToSync.onChanged.add(handleDataChange);
      console.log("sheetChangeEventListener added");
    }

    // loading table of contents sheet (all cooperations)
    let contentSheet;
    const fullSheetName = getTableOfContentSheetName(project.name);
    let isNewSheet = !existingSheets.includes(fullSheetName);

    if (isNewSheet) {
      contentSheet = await addSheet(context, fullSheetName);
    } else {
      contentSheet = context.workbook.worksheets.getItem(fullSheetName);
    }

    contentSheet.position = 0;
    await context.sync();

    await loadTableOfContentsIntoSheet(context, contentSheet, project);
  });
};

const getTableOfContentSheetName = (projectName) => {
  if (projectName.length > 15) {
    projectName = projectName.slice(0, 13) + "..";
  }
  return `Tartalomjegyzék ${projectName}`;
};

const loadSheetContent = async (context, currentSheet, project, cooperation, logoFiles) => {
  await loadHeaderAndFooterIntoSheet(currentSheet, project, cooperation, logoFiles[0], logoFiles[1]);
  formatColumns(currentSheet);
  
  if (cooperation.topics.length !== 0) {
    // Insert data (Topics and Entries) into the sheet.
    const cooperationDataCells = convertCooperationDataToCells(cooperation);
      await insertData(false, context, currentSheet, "A", HEADER_CELLS_HEIGHT + 1, cooperationDataCells);
      
      const usedRange = currentSheet.getUsedRange();
      usedRange.load("rowCount");
      await context.sync();
      
      // set wrapping text to the entry column
      currentSheet.getRange(`${ENTRY_COL}1:${ENTRY_COL}${usedRange.rowCount}`).format.wrapText = true;

      // Grouping topics and entries.
      const entryTopicRowCount = usedRange.rowCount - HEADER_CELLS_HEIGHT - FOOTER_CELLS_HEIGHT;
      const groupRange = currentSheet.getRange(`${NUMLIST_COL}7:${NUMLIST_COL}${HEADER_CELLS_HEIGHT + entryTopicRowCount}`);
      groupRange.load();
      await context.sync();

    let groupedRows = [];
    for (let index = 0; index < groupRange.text.length; index++) {
      const rowNum = groupRange.text[index].join();
      const lastNum = rowNum.substring(rowNum.lastIndexOf(".") + 1);
      if (!groupedRows.includes(lastNum) && lastNum != "") {
        groupedRows.push(lastNum);
        let lastIndex = groupRange.text.findLastIndex((row) => {
          let numArr = row.join().split(".");
          return numArr.includes(lastNum);
        });
        let lastOccurance = lastIndex > 0 ? lastIndex : 0;
        currentSheet.getRange(`${index + 7}:${lastOccurance + 7}`).group(Excel.GroupOption.byRows);
        await context.sync();
      }
    }
  }

  // set filtering
  const filterRange = currentSheet.getRange(`${NR_COL}${HEADER_CELLS_HEIGHT}:${PARENT_ROW_NO_COLUMN_LETTER}${HEADER_CELLS_HEIGHT}`);
  filterRange.values = [[EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY, HAS_NO_PARENT]];
  currentSheet.autoFilter.apply(filterRange, IS_CLOSED_COLUMN_INDEX, {
    criterion1: `=${OPEN}`,
    criterion2: `=${EMPTY}`,
    operator: Excel.FilterOperator.or,
    filterOn: Excel.FilterOn.custom,
  });
};

const loadTableOfContentsIntoSheet = async (context, contentSheet, project) => {
  console.log("Start loadTaleOfContentsIntoSheet");
  contentSheet.getRange("A1:A1").format.columnWidth = 15;
  contentSheet.getRange("B1:B1").format.columnWidth = 45;
  contentSheet.getRange("C1:C1").format.columnWidth = 65;
  contentSheet.getRange("D1:D1").format.columnWidth = 350;
  contentSheet.getRange("A:D").format.horizontalAlignment = "Left";

  const projectTitle = contentSheet.getRange("A1:A1");
  projectTitle.values = [[project.name]];
  projectTitle.format.font.size = 25;
  projectTitle.format.font.bold = true;

  const coopTitles = contentSheet.getRange("A3:D3");
  coopTitles.values = [["", "", "Dátum", "Kooperáció neve"]];
  coopTitles.format.font.size = 14;
  coopTitles.format.font.bold = true;

  const coopsNum = project.cooperations.length;
  const coopsRange = contentSheet.getRange(`A4:D${3 + coopsNum}`);
  let reversedCooperations = [];
  for (var i = project.cooperations.length - 1; i >= 0; i--) {
    reversedCooperations.push(project.cooperations[i]);
  }
  let coopsList = [];
  reversedCooperations.map((coop, i) => {
    coopsList.push([coopsNum - i, "betöltés", moment(coop.date).format("YYYY.MM.DD"), coop.name]);
  });
  coopsRange.values = coopsList;

  // Create a hyperlink to each cooperation date
  const coopsDateRange = contentSheet.getRange(`C4:C${3 + coopsNum}`);
  coopsDateRange.load("text");
  await context.sync();

  const sheets = context.workbook.worksheets;
  sheets.load("items/name");
  await context.sync();

  for (let i = 0; i < coopsDateRange.text.length; i++) {
    let cellRange = coopsDateRange.getCell(i, 0);
    let cellText = coopsDateRange.text[i][0];
    const cellDate = cellText.replaceAll(".", "-");

    let hyperlink = {
      textToDisplay: cellText,
      screenTip:
        sheets.items.filter((sheet) => sheet.name == cellDate).length > 0
          ? "Tovább a(z) " + cellDate + " kooperációra"
          : "A sheet betöltése szükséges",
      documentReference: `'${cellDate}'!A1`,
    };
    cellRange.hyperlink = hyperlink;
  }
  console.log("loadTaleOfContentsIntoSheet finished running.");
};

const showSelectedCell = async (
  project,
  logoFiles,
  filteredCooperations,
  cooperationStore,
  prevCell,
  setActiveCooperation
) => {
  await excelWrapper(async (context) => {
    console.log("showSelectedCell ran");
    const recentCoop = filteredCooperations[0];
    let activeCell;
    let activeSheet;
    let usedRange;
    
    // This try-catch is needed, because when clicking on an image directly after
    // loading sheet in Desktop Excel, it causes an error for some reason. (Fix for issue #160)
    try {
      activeCell = context.workbook.getActiveCell();
      activeSheet = context.workbook.worksheets.getActiveWorksheet();
      usedRange = activeSheet.getUsedRange();
      usedRange.load("rowCount");
      activeCell.load();
      await context.sync();
    } catch (error) {
      if (error.code !== "InvalidSelection") throw error;
      
      return;
    }
    
    const cellDate = activeCell.address.split("!")[0].slice(1, -1); // .slice() to remove unnecessary quotation marks
    if (prevCell.address !== cellDate || prevCell.address == "") {
      prevCell.address = cellDate;
      let CellC3Val = activeSheet.getRange("C3:C3");
      CellC3Val.load("text");
      await context.sync();
      const coopOrderNum = CellC3Val.text[0][0].split(".")[0];

      const newCoop = project.cooperations.filter((coop) => coop.date == cellDate)[0];
      if (newCoop != undefined) {
        newCoop.orderNum = coopOrderNum;
        // if (project.cooperations[0].date == newCoop.date) {
        //   newCoop.isDisabled = false;
        // } else {
        //   newCoop.isDisabled = true;
        // }
        setActiveCooperation(newCoop);
      }
    }
    const cellColumn = activeCell.columnIndex + 1;
    const cellRow = activeCell.rowIndex + 1;
    let cellType = null;
    let cellStatus = null;

    if (cellColumn === TYPE_COL_NUM) {
      const cellTypeRange = activeSheet.getRange(`${TYPE_COL}${cellRow}`);
      cellTypeRange.load();
      await context.sync();
      cellType = cellTypeRange.text[0].toString();
    }
    if (cellColumn === STATUS_CLOSING_COL_NUM) {
      const cellStatusRange = activeSheet.getRange(`${STATUS_CLOSING_COL}${cellRow}`);
      cellStatusRange.load();
      await context.sync();
      cellStatus = cellStatusRange.text[0].toString();
    }

    // Remove row button activity
    const selectedRowId = activeSheet.getRange(`${ID_COLUMN_LETTER}${cellRow}`);
    selectedRowId.load("values");
    await context.sync();

    if (
      selectedRowId.values[0][0] !== EMPTY ||
      cellRow <= HEADER_CELLS_HEIGHT ||
      cellRow > usedRange.rowCount - FOOTER_CELLS_HEIGHT
    ) {
      cooperationStore.setDisableRemoveRowBtn(true);
    } else {
      cooperationStore.setDisableRemoveRowBtn(false);
    }

    // Other buttons activity
    if (
      cellDate == getTableOfContentSheetName(project.name) &&
      cellColumn == 2 &&
      cellRow > TABLE_OF_CONTENTS_HEADER_HEIGHT &&
      cellRow <= project.cooperations.length + TABLE_OF_CONTENTS_HEADER_HEIGHT
    ) {
      const coopDate = activeSheet.getRange(`C${cellRow}:C${cellRow}`);
      coopDate.load("text");
      await context.sync();
      const coopDateStr = coopDate.text[0].join().replaceAll(".", "-");

      const sheets = context.workbook.worksheets;
      sheets.load("items/name");
      await context.sync();
      let currentSheet;
      console.log(sheets.items[0].name, coopDateStr);
      if (sheets.items.filter((sheet) => sheet.name === coopDateStr).length == 0) {
        currentSheet = sheets.add(coopDateStr);
        currentSheet.position = 1;
        const coopToBeLoaded = project.cooperations.filter((coop) => coop.date == coopDateStr);
        await loadSheetContent(context, currentSheet, project, coopToBeLoaded[0], logoFiles);
      }
    } else {
      const entryTopicRowCount = usedRange.rowCount - HEADER_CELLS_HEIGHT - FOOTER_CELLS_HEIGHT;
      if (cellDate == recentCoop.date) {
        cooperationStore.setShowInsertBtns(true); // the latest coop
        cooperationStore.setDisableSyncronisationBtn(false);
        if (
          cellColumn <= MAX_COLUMN_NUM &&
          cellRow > HEADER_ROW_END &&
          cellRow <= entryTopicRowCount + HEADER_CELLS_HEIGHT &&
          (cellRow !== FILTER_ROW || usedRange.rowCount == NEW_SHEET_ROWCOUNT)
        ) {
          const LColValue = activeSheet.getRange(`L${cellRow}:L${cellRow}`);
          LColValue.load("text");
          await context.sync();

          if (usedRange.rowCount == NEW_SHEET_ROWCOUNT) {
            // the cooperation has no topic/entry
            cooperationStore.setDisableInsertMainEntryBtn(true);
            cooperationStore.setDisableInsertSubentryBtn(true);
          } else {
            if (LColValue.text[0][0].includes("T")) cooperationStore.setDisableInsertMainEntryBtn(true);
            else cooperationStore.setDisableInsertMainEntryBtn(false);
            cooperationStore.setDisableInsertSubentryBtn(false);
          }
          if (LColValue.text[0][0].includes("E")) {
            cooperationStore.setDisableInsertMainTopicBtn(true);
            cooperationStore.setDisableInsertSubtopicBtn(true);
          } else {
            cooperationStore.setDisableInsertMainTopicBtn(false);
            if (usedRange.rowCount == NEW_SHEET_ROWCOUNT) cooperationStore.setDisableInsertSubtopicBtn(true);
            // the cooperation has no topic/entry
            else cooperationStore.setDisableInsertSubtopicBtn(false);
          }
        } else {
          cooperationStore.setDisableInsertMainEntryBtn(true);
          cooperationStore.setDisableInsertSubentryBtn(true);
          cooperationStore.setDisableInsertMainTopicBtn(true);
          cooperationStore.setDisableInsertSubtopicBtn(true);
        }
      } else {
        cooperationStore.setShowInsertBtns(false); // not the latest coop
        cooperationStore.setDisableSyncronisationBtn(true);
      }

      if (
        prevCell.cellColumn <= MAX_COLUMN_NUM &&
        prevCell.cellRow > HEADER_CELLS_HEIGHT &&
        prevCell.cellRow <= entryTopicRowCount + HEADER_CELLS_HEIGHT
      ) {
        // if the prevcell type is changed to "I", the deadline should be changed to "foly"
        let typeCellRange = activeSheet.getRange(`${TYPE_COL}${prevCell.cellRow}`);
        typeCellRange.load();
        await context.sync();
        // currPrevCelltypeValue contains the refreshed prev cell type, if it was changed
        const currPrevCellTypeValue = typeCellRange.text[0].toString();
        // prevCell.initialTypeValue contains the initial (unchanged) prev cell type, if the prevcell was changes
        if (currPrevCellTypeValue === ELOG_COL && prevCell.initialTypeValue && prevCell.initialTypeValue !== ELOG_COL) {
          const deadlineCell = activeSheet.getRange(`${DEADLINE_COL}${prevCell.cellRow}`);
          deadlineCell.load();
          await context.sync();

          deadlineCell.values = [["foly"]];
          await context.sync();
        }

        let errorMsg = [];
        let prevCellRowRange = activeSheet.getRange(`${NR_COL}${prevCell.cellRow}:${ATTACHMENT_COL}${prevCell.cellRow}`);
        let MainSubValue = activeSheet.getRange(`${MAIN_SUB_COL}${prevCell.cellRow}`);
        
        prevCellRowRange.load();
        MainSubValue.load();
        await context.sync();
        MainSubValue = MainSubValue.text[0].toString();


        // topic abbrevation validation
        if (MainSubValue.includes("T")) {
          const prevCellAbbreviation = prevCellRowRange.text[0][0]; // first cell of the row
          if (prevCellAbbreviation === "") {
            errorMsg.push("Rövidítés megadása kötelező.");
          }
        }

        // topic name / entry description validation
        const prevCellNameDesc = prevCellRowRange.text[0][2]; // third cell of the row
        if (prevCellNameDesc === "") {
          if (MainSubValue.includes("E")) {
            errorMsg.push("Leírás megadása kötelező.");
          } else {
            errorMsg.push("Név megadása kötelező.");
          }
        }

        // entry deadline validation
        const prevCellDeadline = prevCellRowRange.text[0][4]; // fifth cell of the row
        if (
          !moment(prevCellDeadline, "YYYY.MM.DD.", true).isValid() &&
          !moment(prevCellDeadline, "YYYY.MM.DD", true).isValid() &&
          prevCellDeadline !== "ASAP" &&
          prevCellDeadline !== "foly" &&
          prevCellDeadline !== ""
        ) {
          errorMsg.push("Nem megfelelő a dátum, elfogadott dátumok: YYYY.MM.DD., ASAP, foly.");
        } else if (prevCellDeadline === "" && MainSubValue.includes("E")) {
          errorMsg.push("Dátum megadása kötelező.");
        }

        // status closing validation
        const resp = await validateStatusClosing(context, prevCell.cellRow, entryTopicRowCount);
        if (resp !== "") {
          errorMsg.push(resp);
        } else {
            await checkParentsStatus(
              context,
              activeSheet,
              prevCell.cellRow,
              usedRange,
              false,
              prevCell
            );
        }

        const errorCell = activeSheet.getRange(`${ERROR_COLUMN_LETTER}${prevCell.cellRow}`);
        loadErrorMsg(context, activeSheet, errorCell, errorMsg);
      }
    }
    prevCell.cellRow = cellRow;
    prevCell.cellColumn = cellColumn;
    prevCell.initialTypeValue = cellType;
    prevCell.initialStatusValue = cellStatus;
  })
}

const loadErrorMsg = async (context, activeSheet, errorCell, errorMsg) => {
  errorCell.values = [[errorMsg.join(" ")]];
  await context.sync();
};

const validateStatusClosing = async (context, cellRow, entryTopicRowCount) => {
  const activeSheet = context.workbook.worksheets.getActiveWorksheet();
  const HColumnRange = activeSheet.getRange(
    `${STATUS_CLOSING_COL}${cellRow}:${STATUS_CLOSING_COL}${HEADER_ROW_END + entryTopicRowCount + 1}`
  );
  const QColumnRange = activeSheet.getRange(
    `${NUMLIST_COL}${cellRow}:${NUMLIST_COL}${HEADER_ROW_END + entryTopicRowCount + 1}`
  );
  HColumnRange.load("text");
  QColumnRange.load("text");
  await context.sync();

  const numList = QColumnRange.text[0].join();
  const lastNum = numList.substring(numList.lastIndexOf(".") + 1);
  let allIsClosed = true;

  if (HColumnRange.text[0].join() == CLOSED) {
    QColumnRange.text.map((numList, i) => {
      const numbers = numList.toString().split(".");
      if (numbers.includes(lastNum)) {
        if (HColumnRange.text[i].join() == OPEN && i !== 0) {
          allIsClosed = false;
        }
      }
    });
    if (!allIsClosed) {
      return "Nem lehet lezárni, mert van olyan altémaköre/bejegyzése, ami még nyitott.";
    }
  }
  return "";
};

// Add empty sheet with the specified name.
// If the sheet already exists, clear it.
const addSheet = async (context, name, position = null) => {
  const sheets = context.workbook.worksheets;
  let sheet;

  sheets.load("items/name");
  await context.sync();

  if (sheets.items.filter((sheet) => sheet.name === name).length !== 0) {
    sheet = sheets.getItem(name);
  } else {
    sheet = sheets.add(name);
  }

  return sheet;
};

// Identical to the fetchData() function, but returns
// the data instead of storing it in a store.
export const fetchAndReturnData = async (url, showErrorMessage) => {
  try {
    const resp = await fetch(url, {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
        'X-CSRFToken': getCookie('csrftoken') ,
      },
    });
    const data = await resp.json();
    if (resp.ok) {
      return data;
    } else {
      if (data.message) showErrorMessage(data.message);
      else if (data.detail) showErrorMessage(data.detail);
      else showErrorMessage("A kért tartalom a szerveren történt hiba miatt nem elérhető");
    }
  } catch (err) {
    handleErrors(err, showErrorMessage, "Megszakadt a kapcsolat a szerverrel (5)!");
  }
};

const excelWrapper = async (func) => {
  await Excel.run({ delayForCellEdit: true }, async (context) => {
    OfficeExtension.config.extendedErrorLogging = true;
    const result = await func(context);
    await context.sync();

    return result;
  }).catch(function (error) {
    // For more info about the Office error: 
    //    https://learn.microsoft.com/en-us/javascript/api/office/officeextension.error?view=common-js-preview#office-officeextension-error-innererror-member
    if (error instanceof OfficeExtension.Error) {
        console.error("Error type: ", error.name);
        console.error("Error message: ", error.message)
        console.error("Debug info: ")
        console.log(JSON.stringify(error.debugInfo));
        console.error("Trace messages:")
        console.log(error.traceMessages)
    } else {
      console.error("Excel wrapper error: ");
      console.error(error);
    }

    document.setOpenErrorDialog(true);
    document.setErrorDialogMessage("Excel API hiba történt, kérem próbálja újra. (" + error + ")");
  });
};

const loadHeaderAndFooterIntoSheet = async (sheet, project, cooperation, logoFile, logo2File) => {
  // Add header & footer
  loadHeader(sheet, project, cooperation);
  loadFooter(sheet, HEADER_CELLS_HEIGHT + 1);

  // F column
  const typeRange = sheet.getRange(`${TYPE_COL}:${TYPE_COL}`);
  const typeList = {
    list: {
      inCellDropDown: true,
      source: "F, I, D",
    },
  };
  typeRange.dataValidation.rule = typeList;

  // E Column, formatting
  const deadlineRange = sheet.getRange(`${DEADLINE_COL}:${DEADLINE_COL}`);
  deadlineRange.format.horizontalAlignment = "Center";

  // G Column, Add conditional formatting
  const statusRange = sheet.getRange(`${SIGNAL_COL}:${SIGNAL_COL}`);
  statusRange.format.horizontalAlignment = "Center";
  const conditionalFormat = statusRange.conditionalFormats.add(Excel.ConditionalFormatType.iconSet);
  const iconSetCF = conditionalFormat.iconSet;
  iconSetCF.style = Excel.IconSet.fourTrafficLights;
  iconSetCF.showIconOnly = true;

  iconSetCF.criteria = [
    { showIconOnly: true },
    {
      type: Excel.ConditionalFormatIconRuleType.number,
      operator: Excel.ConditionalIconCriterionOperator.greaterThanOrEqual,
      formula: "=1",
    },
    {
      type: Excel.ConditionalFormatIconRuleType.number,
      operator: Excel.ConditionalIconCriterionOperator.greaterThanOrEqual,
      formula: "=2",
    },
    {
      type: Excel.ConditionalFormatIconRuleType.number,
      operator: Excel.ConditionalIconCriterionOperator.greaterThanOrEqual,
      formula: "=3",
    },
  ];

  const statusList = {
    list: {
      inCellDropDown: true,
      source: "0, 1, 2, 3",
    },
  };
  statusRange.dataValidation.rule = statusList;

  const range = sheet.getRange(`${NR_COL}:${ATTACHMENT_COL}`);

  // Conditional formatting new ones
  const coopConditionalFormat = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  coopConditionalFormat.custom.rule.formula = `=IF($${DATE_COL}1=$E$4,TRUE)`;
  coopConditionalFormat.custom.format.font.color = NEW_ENTRY_COLOR;

  // Conditional formatting: edited rows
  const IncorrectEditedRowConditionalFormat = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  IncorrectEditedRowConditionalFormat.custom.rule.formula = `=IF($${ERROR_COLUMN_LETTER}1<>"",TRUE)`;
  IncorrectEditedRowConditionalFormat.custom.format.fill.color = LIGHT_RED_COLOR;

  const editedRowConditionalFormat = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  editedRowConditionalFormat.custom.rule.formula = `=IF($${WAS_MODIFIED_COLUMN_LETTER}1="${WAS_MODIFIED}",TRUE)`;
  editedRowConditionalFormat.custom.format.fill.color = LIGHT_GREEN_COLOR;

  // Conditional formatting: main and subtopic rows
  const mainTopicConditionalFormat = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  mainTopicConditionalFormat.custom.rule.formula = `=IF($${MAIN_SUB_COL}1="MT",TRUE)`;
  mainTopicConditionalFormat.custom.format.fill.color = DARK_GRAY_COLOR;
  mainTopicConditionalFormat.custom.format.font.bold = true;

  const subTopicConditionalFormat = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  subTopicConditionalFormat.custom.rule.formula = `=IF($${MAIN_SUB_COL}1="ST",TRUE)`;
  subTopicConditionalFormat.custom.format.fill.color = LIGHT_GRAY_COLOR;
  subTopicConditionalFormat.custom.format.font.bold = true;

  // H cloumn
  const closedRange = sheet.getRange(`${STATUS_CLOSING_COL}:${STATUS_CLOSING_COL}`);
  const closedList = {
    list: {
      inCellDropDown: true,
      source: "o, c",
    },
  };
  closedRange.dataValidation.rule = closedList;

  if (logoFile) {
    await addImgToSheet(sheet, logoFile, 0);
  }

  if (logo2File) {
    await addImgToSheet(sheet, logo2File, LOGO_RIGHT_POSITION);
  }
  console.log("loadHeaderAndFooterIntoSheet finished running.");

  // I Column
  const eLogRange = sheet.getRange(`${ELOG_COL}:${ELOG_COL}`);
  const eLogList = {
    list: {
      inCellDropDown: true,
      source: `${HAS_ELOG}, ${HAS_NO_ELOG}`,
    },
  };
  eLogRange.dataValidation.rule = eLogList;
};

const loadHeader = (sheet, project, cooperation) => {
  console.log("loadHeader ran");

  console.log("loadHeader sheet", sheet);

  const headerRange = sheet.getRange(`${NR_COL}1:${ATTACHMENT_COL}5`);
  console.log("headerRange ", headerRange);
  sheet.getRange("B4:C4").merge();
  sheet.getRange("F5:H5").merge();
  headerRange.values = getHeaderCells({ project, cooperation });
  headerRange.format.horizontalAlignment = "Center";
  sheet.getRange("B4:C4").format.horizontalAlignment = "Left";
  sheet.getRange("A1:E4").format.font.size = 14;
  const headerTitleRange = sheet.getRange(`${NR_COL}5:${ATTACHMENT_COL}5`);
  headerTitleRange.format.fill.color = DARK_GRAY_COLOR;
  headerTitleRange.format.font.bold = true;
  sheet.getRange(`${ENTRY_COL}1:${ENTRY_COL}2`).format.font.bold = true;
  sheet.getRange(`${ENTRY_COL}1`).format.font.size = 25;
  sheet.getRange(`${ENTRY_COL}1`).format.columnWidth = 500;
  sheet.getRange(`${NR_COL}1:${DATE_COL}1`).format.columnWidth = 60;
  sheet.getRange(`${DEADLINE_COL}1`).format.columnWidth = 80;
  sheet.getRange(`${ATTACHMENT_COL}1`).format.columnWidth = 70;
  sheet.getRange(`${TYPE_COL}1:${STATUS_CLOSING_COL}1`).format.columnWidth = 25;
  sheet.freezePanes.freezeRows(HEADER_CELLS_HEIGHT);
};

const loadFooter = (sheet, startRowNo) => {
  const footerRange = sheet.getRange(`A${startRowNo}:G${startRowNo + 5}`);
  sheet.getRange(`A${startRowNo}:B${startRowNo + 2}`).format.font.bold = true;
  sheet.getRange(`B${startRowNo + 1}`).format.font.color = NEW_ENTRY_COLOR;
  sheet.getRange(`B${startRowNo + 2}`).format.font.color = CRITICAL_ENTRY_COLOR;
  sheet.getRange(`F${startRowNo + 1}:F${startRowNo + 4}`).format.horizontalAlignment = "Right";
  footerRange.values = getFooterCells();
};

const addImgToSheet = async (sheet, fileObj, positionRight) => {
  const img = sheet.shapes.addImage(cutBase64(fileObj.data));
  if (fileObj.size.width / LOGO_MAX_WIDTH > fileObj.size.height / LOGO_MAX_HEIGHT) {
    img.width = LOGO_MAX_WIDTH;
    const ratio = LOGO_MAX_WIDTH / fileObj.size.width;
    img.height = fileObj.size.height * ratio;
  } else {
    img.height = LOGO_MAX_HEIGHT;
    const ratio = LOGO_MAX_HEIGHT / fileObj.size.height;
    img.width = fileObj.size.width * ratio;
  }

  if (positionRight > 0) {
    img.load("left");
    await sheet.context.sync();
    img.left = positionRight - img.width;
  } else {
    img.left = positionRight;
  }
};

const insertData = async (isNewRow, context, sheet, startCol, startRow, dataToAdd, overwrite = false) => {
  if (dataToAdd.length === 0) return;

  const [endCol, endRow] = getEndRange(startCol, startRow, dataToAdd);
  if (!overwrite) {
    let copyRange = sheet.getRange(`${startRow}:${endRow}`);
    copyRange.insert(Excel.InsertShiftDirection.down);
  }
  let resultRange = sheet.getRange(`${startCol}${startRow}:${endCol}${endRow}`);
  resultRange.values = dataToAdd;

  if (isNewRow) {
    let usedRange = sheet.getUsedRange();
    usedRange.load("rowCount");
    await context.sync();

    await checkParentsStatus(
      context,
      sheet,
      startRow,
      usedRange,
      true
    );
  }
  return resultRange;
};

const checkParentsStatus = async (context, activeSheet, currRowNum, usedRange, isNewRow, prevCell = null) => {
  const currNumCell = activeSheet.getRange(`${NUMLIST_COL}${currRowNum}`);
  const statusRange = activeSheet.getRange(`${STATUS_CLOSING_COL}${HEADER_CELLS_HEIGHT + 1}:${STATUS_CLOSING_COL}${usedRange.rowCount-FOOTER_CELLS_HEIGHT}`);
  const numRange = activeSheet.getRange(`${NUMLIST_COL}${HEADER_CELLS_HEIGHT + 1}:${NUMLIST_COL}${usedRange.rowCount-FOOTER_CELLS_HEIGHT}`);
  currNumCell.load();
  statusRange.load();
  numRange.load();
  await context.sync();

  const currCellNums = currNumCell.text[0].toString();
  const currParentNums =  currCellNums.substring(0, currCellNums.lastIndexOf('.')); // remove the last number
  const currParentNumsArr = currParentNums.split(".");
  const numRangeArr = numRange.text;

  for (const currParentNum of currParentNumsArr) {
    const parentRowIndex = numRangeArr.findIndex((num) => {
      const numArr = num.toString().split(".");
      return numArr.includes(currParentNum)
    });

    if (
        isNewRow &&
        statusRange.text[parentRowIndex] &&
        statusRange.text[parentRowIndex].toString() === CLOSED 
    ) { 
        await rewriteStatusToOpen(context, activeSheet, parentRowIndex);
        const currStarRange = activeSheet.getRange(`${STAR_COL}${HEADER_CELLS_HEIGHT + parentRowIndex + 1}`);
        currStarRange.load();
        await context.sync();
        currStarRange.values = [["*"]];
      } else if (
        !isNewRow &&
        statusRange.text[parentRowIndex] &&
        statusRange.text[parentRowIndex].toString() === CLOSED &&
        prevCell &&
        prevCell.initialStatusValue === CLOSED &&
        statusRange.text[prevCell.cellRow - HEADER_CELLS_HEIGHT -1].toString() === OPEN
      ) {
        await rewriteStatusToOpen(context, activeSheet, parentRowIndex);
      }
  }
}

const rewriteStatusToOpen = async (context, activeSheet, parentRowIndex) => {
  const currStatusRange = activeSheet.getRange(`${STATUS_CLOSING_COL}${HEADER_CELLS_HEIGHT + parentRowIndex + 1}`);
        currStatusRange.load();
        await context.sync();
        currStatusRange.values = [[OPEN]];
}



const getEndRange = (startCol, startRow, dataToAdd) => {
  const startRowIndex = ALPHABET.search(startCol);
  if (startRowIndex == -1 || isNaN(startRow)) {
    console.error(`Invalid field ${startCol}:${startRow}.`);
    return;
  }
  const endRow = startRow + dataToAdd.length - 1;
  const endColumn = ALPHABET.charAt(startRowIndex + dataToAdd[0].length - 1);
  return [endColumn, endRow];
};

const getAccessToken = async () => {
  if (!window.officeauthaccesstoken) {
    window.officeauthaccesstoken = await acquireAccessTokenFromOffice();
  }
  return window.officeauthaccesstoken;
};

const acquireAccessTokenFromOffice = async () => {
  try {
    if (PROJECT_STAGE === "dev") return "";
    const token = await Office.auth.getAccessToken({ isRest: true, enableNewHosts: 1 });
    return token;
  } catch (error) {
    window.officeauthaccesstoken = null;

    if (error.code === 13004) {
      throw {
        name: "Office authentikációs hiba (" + error.code + ")",
        message: "Kérem törölje a cache-t, és próbálkozzon újra!",
      };
    } else {
      throw { name: "Office authentikációs hiba (" + error.code + ")", message: error.message };
    }
  }
};

const handleErrors = async (exception, showErrorMessage, message) => {
  //log error to console
  console.error(">>handleErrors:");
  if (message) console.error(message);
  console.error(exception);

  let msg;
  //show error message (if exists) to user incl. some debug info (most probably DOMException or TypeException returned by fetch)
  if (showErrorMessage) {
    if (message) {
      if (exception && exception.name && exception.message)
        msg = message + "(" + exception.name + ", " + exception.message + ")";
      else msg = message;
    } else {
      msg = "Ismeretlen hiba történt! Kérem próbálja újra a műveletet!";
    }
    showErrorMessage(msg);
  } else {
    msg = "";
  }
  return msg;
};

const orderCooperationDataRows = (cooperation) => {
  const main_topics = cooperation.topics.filter((topic) => topic.main_topic == null);
  return recurisveOrderTopicRows(main_topics);
};

const recurisveOrderTopicRows = (topics) => {
  let rows = [];
  topics.forEach((topic) => {
    rows.push({ ...topic, dataType: "T" });
    rows.push(...recurisveOrderEntryRows(topic.entries));
    rows.push(...recurisveOrderTopicRows(topic.sub_topics));
  });
  return rows;
};

const recurisveOrderEntryRows = (entries) => {
  let rows = [];
  entries.forEach((entry) => {
    rows.push({ ...entry, dataType: "E" });
    rows.push(...recurisveOrderEntryRows(entry.sub_entries));
  });
  return rows;
};

const getCooperationIdFromSheet = async (context, sheet) => {
  const cooperationIdCell = sheet.getRange(HEADER_COOPERATION_ID_CELL);
  cooperationIdCell.load("values");
  await context.sync();
  return cooperationIdCell.values[0][0];
};

const removeSelectedExcelLine = async () => {
  await excelWrapper(async (context) => {
    const activeSheet = context.workbook.worksheets.getActiveWorksheet();
    const activeSheetUsedRange = activeSheet.getUsedRange();
    activeSheetUsedRange.load("values, rowCount");

    const selection = context.workbook.getSelectedRange();
    selection.load("rowIndex");
    await context.sync();

    let selectedRowIndex = selection.rowIndex;
    const selectedRowId = activeSheetUsedRange.values[selectedRowIndex][ID_COLUMN_INDEX];

    if (selectedRowId) return;

    const relationToLookFor = activeSheetUsedRange.values[selectedRowIndex][RELATION_STRING_COLUMN_INDEX];
    while (selectedRowIndex <= activeSheetUsedRange.rowCount - FOOTER_CELLS_HEIGHT) {
      selectedRowIndex++;
      const currentRow = activeSheetUsedRange.values[selectedRowIndex][RELATION_STRING_COLUMN_INDEX];
      if (!currentRow.startsWith(relationToLookFor)) break;
    }

    await disableEventsWrapper(context, async () => {
      const rangeToDelete = activeSheet.getRange(`${selection.rowIndex + 1}:${selectedRowIndex}`);
      rangeToDelete.delete(Excel.DeleteShiftDirection.up);
    });
  });
};

const disableEventsWrapper = async (context, fn) => {
  context.runtime.enableEvents = false;
  await context.sync();
  try {
    await fn();
  } finally {
    context.runtime.enableEvents = true;
    await context.sync();
  }
};

export {
  disableEventsWrapper,
  removeSelectedExcelLine,
  orderCooperationDataRows,
  getCooperationIdFromSheet,
  checkExistingSheets,
  loadCooperationsIntoExcel,
  loadFooter,
  getAccessToken,
  handleErrors,
  excelWrapper,
  insertData,
  HEADER_CELLS_HEIGHT,
};
