import * as React from 'react';
import { BasicButton } from "./styles/ButtonStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function InformDialog({description, setOpen}) {

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
          <CheckCircleOutlineIcon sx={{fontSize: 60}} color="success" />
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" sx={{textAlign: 'center'}}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <BasicButton sx={{marginRight: '0px'}} onClick={() => setOpen(false)} color="success" variant="contained">Rendben</BasicButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}