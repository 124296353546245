import * as React from 'react';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BasicButton } from "./styles/ButtonStyles";

import JobTitleDialog from './JobTitleDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EmployeeSelect({listEmployees, setListEmployees, setTableEmployees, tableEmployees, multiple, project_id, coop_id}) {
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const updateEmployees = () => {
    if(multiple) {
        const emp = (selectedEmployees.filter(emp => !tableEmployees.includes(emp)));          
        setTableEmployees(tableEmployees => [...tableEmployees, ...emp]);
        const listEmp = (listEmployees.filter(emp => !selectedEmployees.includes(emp)));
        setListEmployees(listEmp);
    }
    setSelectedEmployees([]);
  }

  return (
    <div style={{ marginTop: '15px', marginBottom: '10px' }}>
        <Autocomplete
        value={selectedEmployees}
        multiple={multiple}
        id="checkboxes-tags-demo"
        options={listEmployees}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name  || ""}
        renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
            />
            {option.name} {option.company} {option.job_title} {option.email} {option.phone}
            </li>
        )}
        style={{ width:'70%', display: 'inline-block', marginRight: '10px' }}
        onChange={(event, value) => setSelectedEmployees(value)}
        renderInput={(params) => (
            <TextField {...params} label="Vállalkozó/személy hozzáadása" placeholder="hozzáadás.." color="success"/>
        )}
        />
        {multiple &&
          <BasicButton color="success" variant="contained" sx={{verticalAlign: 'bottom'}} onClick={updateEmployees}>Hozzáadás</BasicButton>
        }
        {!multiple && 
          <JobTitleDialog updateEmployees={updateEmployees} setTableEmployees={setTableEmployees} selectedEmployee={selectedEmployees} setSelectedEmployee={setSelectedEmployees} project_id={project_id} coop_id={coop_id}/>
        }
    </div>
  );
}