import * as React from "react";
import { huHU } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import { getAccessToken, handleErrors } from "../utils/excel_utils";


export const fetchData = async (URL, storeFunction, showErrorMessage) => {
    try {
        const resp = await fetch(URL, {
            headers: {
                'Authorization': 'Bearer ' + await getAccessToken(),
                'X-CSRFToken': getCookie('csrftoken') ,
            }
        });
        const data = await resp.json();
        if (resp.ok) {
            if (storeFunction != null) {storeFunction(data);}
            return data
        } else {
            if (data.message ) showErrorMessage(data.message);
            else if(data.detail) showErrorMessage(data.detail);
            else showErrorMessage('A kért tartalom a szerveren történt hiba miatt nem elérhető');
        }
    }
    catch(err) {
        handleErrors(err, showErrorMessage, 'Megszakadt a kapcsolat a szerverrel (0)!');
    }
}

export const requestData = async (URL, method, reqData, storeFunction, success, showErrorMessage) => {
    try {
        const resp = await fetch(URL, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + await getAccessToken(),
            'X-CSRFToken': getCookie('csrftoken') ,
        },
        body: JSON.stringify(reqData),
        });
        const data = await resp.json();
        if (resp.ok) {
            if (storeFunction != null) storeFunction(data);
            if (success != null) success();
            return data;
        } else {
            if (data.message ) showErrorMessage(data.message);
            else if(data.detail) showErrorMessage(data.detail);
            else showErrorMessage('A kért tartalom a szerveren történt hiba miatt nem elérhető');
            return null;
        }
    }
    catch(err) {
        handleErrors(err, showErrorMessage, 'Megszakadt a kapcsolat a szerverrel (1)!');
        return null;
    }
}

export const requestFileData = async (URL, method, formData, storeFunction, success, showErrorMessage) => {
        try {
            const resp = await fetch(URL, {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + await getAccessToken(),
                'X-CSRFToken': getCookie('csrftoken') ,
            },
            body: formData,
            });
            const data = await resp.json();
            if (resp.ok) {
                storeFunction(data);
                if (success != null) success();
                return data;
            } else {
                if (data.message ) showErrorMessage(data.message);
                else if(data.detail) showErrorMessage(data.detail);
                else showErrorMessage('A kért tartalom a szerveren történt hiba miatt nem elérhető ('+resp.status+')<br><p>'+ Object.keys(data).map((a)=> a + '=>' + data[a]).join('<br>') +'</p>');
                return "err";
            }
        }
        catch(err) {
            handleErrors(err, showErrorMessage, 'Megszakadt a kapcsolat a szerverrel (2)!');
            return "err";
        }
}

export const requestDeleteData = async (URL, method, id, storeFunction, success, showErrorMessage) => {
    try {
        const resp = await fetch(URL, {
        headers: {
            'Authorization': 'Bearer ' + await getAccessToken(),
            'X-CSRFToken': getCookie('csrftoken') ,
        },
        method: method
        });
        if (resp.ok) {
            if (storeFunction != null) storeFunction(id);
            if (success != null) success();
            return null;
            
        } else {
            const data = await resp.json();
            if (data.message) showErrorMessage(data.message);
            else if(data.detail) showErrorMessage(data.detail);
            else showErrorMessage('A kért tartalom a szerveren történt hiba miatt nem elérhető');
            return 'err';
        }
    }
    catch(err) {
        handleErrors(err, showErrorMessage, 'Megszakadt a kapcsolat a szerverrel! (4)');
        return 'err';
    }
}

export const datagridLocalization = (from, to, count) => {
    return {...huHU.components.MuiDataGrid.defaultProps.localeText,
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) =>`${from}-${to} / ${count}`,
        }}
}

export const getCookie = (cookieName) => {
    return ('; '+document.cookie).split(`; ${cookieName}=`).pop().split(';')[0];
};

export const renderToolTip = (params) => (<Tooltip title={params.value}><span>{params.value}</span></Tooltip>);
