import {
  disableEventsWrapper,
  excelWrapper,
  fetchAndReturnData,
  getAccessToken,
  getCooperationIdFromSheet,
  handleErrors,
  insertData,
  loadFooter,
  orderCooperationDataRows,
} from "./excel_utils";

import { getEntryInsertAfterRow, getTopicInsertAfterRow, readExcelData } from "./manual_insert";

import {
  EMPTY,
  ALPHABET,
  ENTRY_TYPES,
  DONT_ALLOW_EDIT_FROM_COLUMN,
  WAS_MODIFIED,
  HEADER_COOPERATION_DATE_CELL,
  HEADER_COOPERATION_ID_CELL,
  RELATION_STRING_COLUMN_INDEX,
  HAS_NO_PARENT,
  CLOSED,
  HAS_ELOG,
  URL,
  ERROR_COLUMN_LETTER,
  WAS_MODIFIED_COLUMN_LETTER,
  ABBREVIATION_COLUMN_INDEX,
  DATE_COLUMN_INDEX,
  DESCRIPTION_COLUMN_INDEX,
  RESPONSIBLE_COLUMN_INDEX,
  DEADLINE_COLUMN_INDEX,
  TYPE_STR_COLUMN_INDEX,
  TYPE_COLUMN_INDEX,
  STATUS_COLUMN_INDEX,
  IS_CLOSED_COLUMN_INDEX,
  IS_ELOG_COLUMN_INDEX,
  ATTACHMENT_COLUMN_INDEX,
  ID_COLUMN_INDEX,
  ID_COLUMN_LETTER,
  VERSION_NUMBER_NO_COLUMN_INDEX,
  PARENT_ID_COLUMN_INDEX,
  PARENT_ROW_NO_COLUMN_INDEX,
  ROW_NO_COLUMN_INDEX,
  ERROR_COLUMN_INDEX,
  HEADER_CELLS_HEIGHT,
  WAS_MODIFIED_COLUMN_INDEX,
  GET_PARENTS_ID_FX,
  GET_PARENT_ROW_NUMBER_FX,
  RELATION_EXCEL_FX,
  GET_CURRENT_ROW_NO_FX,
} from "./constans";

import { getCorrectTypeCells } from "./formatting_utils";
import { getCookie } from "../components/Utils";

const synchroniseCooperation = async (showErrorMessage) => {
  console.log("MENTÉS");
  await excelWrapper(async (context) => {
    await disableEventsWrapper(context, async () => {
      const allSheets = context.workbook.worksheets;
      allSheets.load("items/name");
      await context.sync();
      const worksheetToSync = allSheets.items.sort(sortSheetsByDate)[0];

      const coopDateRange = worksheetToSync.getRange(HEADER_COOPERATION_DATE_CELL);
      const coopIdRange = worksheetToSync.getRange(HEADER_COOPERATION_ID_CELL);
      coopIdRange.load("values");
      coopDateRange.load("values");

      await context.sync();

      const coopDate = coopDateRange.values[0][0];
      const coopId = coopIdRange.values[0][0];
      const excelData = (await readExcelData(context, worksheetToSync, true)).reverse();

      for (let row in excelData) {
        const currentRow = excelData[row];

        if (!currentRow.wasModified) continue;

        console.log("synchronizing row: ", currentRow);
        const result =
          currentRow.dataType === "T"
            ? await syncTopic(excelData, currentRow, coopId, showErrorMessage)
            : await syncEntry(excelData, currentRow, coopDate, showErrorMessage);

        if (result.error) {
          worksheetToSync.getRange(`${ERROR_COLUMN_LETTER}${currentRow.rowNo}`).values = [[result.error]];
        } else {
          worksheetToSync.getRange(`${ID_COLUMN_LETTER}${currentRow.rowNo}`).values = [[result.id]];
          worksheetToSync.getRange(`${ERROR_COLUMN_LETTER}${currentRow.rowNo}`).values = [[EMPTY]];
          excelData[row].id = result.id;
        }
      }

      // Remove mark ('*') where row's been successfully synced.
      const sheetUsedRange = worksheetToSync.getUsedRange().load(["rowCount"]);
      await context.sync();

      const sheetRowCount = sheetUsedRange.rowCount;
      const errorRange = worksheetToSync.getRange(`${ERROR_COLUMN_LETTER}1:${ERROR_COLUMN_LETTER}${sheetRowCount}`);
      const markedToSyncRange = worksheetToSync.getRange(
        `${WAS_MODIFIED_COLUMN_LETTER}1:${WAS_MODIFIED_COLUMN_LETTER}${sheetRowCount}`
      );

      errorRange.load("values");
      markedToSyncRange.load("values");
      await context.sync();

      const newMarkedColumnValues = [...markedToSyncRange.values];

      for (let i = 0; i < sheetRowCount; i++) {
        if (errorRange.values[i][0] !== EMPTY) continue;
        newMarkedColumnValues[i] = [EMPTY];
      }
      markedToSyncRange.values = newMarkedColumnValues;

      // Synchronize local Excel data with server data.
      const cooperationId = await getCooperationIdFromSheet(context, worksheetToSync);
      const cooperationData = await fetchAndReturnData(`${URL}/api/cooperations/${cooperationId}`, showErrorMessage);
      await syncExcelWithServer(context, worksheetToSync, cooperationData);
      await context.sync();
    });
  });
};

const syncTopic = async (excelData, topic, coopId, showErrorMessage) => {
  const relationArr = topic.relationString.split(".");
  if (relationArr.length !== 1) {
    const parentRowNo = relationArr[relationArr.length - 2];
    topic.main_topic = excelData.find((row) => row.rowNo == parentRowNo).id;
  }
  topic.cooperation = parseInt(coopId);

  return await syncTopicRequest(topic, showErrorMessage);
};

const syncTopicRequest = async (topic, showErrorMessage) => {
  const requestUrl = topic.id ? `${URL}/api/topics/${topic.id}/` : `${URL}/api/topics/`;
  const method = topic.id ? "PATCH" : "POST";
  return await syncJsonRequest(requestUrl, method, topic, showErrorMessage);
};

const syncEntry = async (excelData, entry, coopDate, showErrorMessage) => {
  let topic;
  let main_entry = null;
  const relationArr = entry.relationString.split(".").slice(0, -1).reverse();

  for (let ancestorRowNo of relationArr) {
    const ancestorData = excelData.find((row) => row.rowNo == ancestorRowNo);
    if (!main_entry && ancestorData.dataType === "E") {
      main_entry = ancestorData.id;
      console.log("main_entry: ", main_entry);
    }

    if (ancestorData.dataType === "T") {
      topic = ancestorData.id;
      break;
    }
  }

  entry.topic = parseInt(topic);
  entry.main_entry = parseInt(main_entry);
  entry.type = Object.keys(ENTRY_TYPES).find((key) => ENTRY_TYPES[key] === entry.type);
  console.log("entry", entry);
  console.log("entry.date", entry.date);
  const unixTimeStamp = entry.date ? momentToUnixTimeStamp(entry.date) : momentToUnixTimeStamp(coopDate);
  entry.date = moment(new Date(unixTimeStamp)).format("YYYY-MM-DD");

  return await syncEntryRequest(entry, showErrorMessage);
};

const syncEntryRequest = async (entry, showErrorMessage) => {
  const requestUrl = entry.id ? `${URL}/api/entries/${entry.id}/` : `${URL}/api/entries/`;
  const method = entry.id ? "PATCH" : "POST";
  return await syncJsonRequest(requestUrl, method, entry, showErrorMessage);
};

const readExcelEntry = (row) => {
  return {
    entry_number: row[ABBREVIATION_COLUMN_INDEX],
    date: row[DATE_COLUMN_INDEX],
    description: row[DESCRIPTION_COLUMN_INDEX],
    responsible: row[RESPONSIBLE_COLUMN_INDEX],
    deadline: parseDeadline(row[DEADLINE_COLUMN_INDEX]),
    type: row[TYPE_COLUMN_INDEX],
    status: row[STATUS_COLUMN_INDEX],
    is_closed: row[IS_CLOSED_COLUMN_INDEX] === CLOSED,
    is_enaplo: row[IS_ELOG_COLUMN_INDEX] === HAS_ELOG,
    attachment: row[ATTACHMENT_COLUMN_INDEX],
    id: parseInt(row[ID_COLUMN_INDEX]),
    version_number: row[VERSION_NUMBER_NO_COLUMN_INDEX],

    parentId: row[PARENT_ID_COLUMN_INDEX],
    parentRowNo: row[PARENT_ROW_NO_COLUMN_INDEX],
    relationString: row[RELATION_STRING_COLUMN_INDEX],
    rowNo: row[ROW_NO_COLUMN_INDEX],
    has_errors: row[ERROR_COLUMN_INDEX] ? true : false,
    dataType: "E",
  };
};


// We need to parse the deadline, because excel stores dates not
// as strings, and not as unix timestamps, but as moment timestamps.
const parseDeadline = (value) => {
  if (value == "foly" || value == "ASAP") return value;
  
  // If the value is an invalid date, 
  // throw back the original value, & let the backend reject it.
  const unixTimestamp = new Date(momentToUnixTimeStamp(value));
  if (!unixTimestamp instanceof Date || isNaN(unixTimestamp)) return value;

  return formatDate(unixTimestamp); 
}

const formatDate = (date) => {
  return (
    date.getFullYear() + "." + 
    ("0" + (date.getMonth() + 1)).slice(-2) + "." + 
    ("0" + date.getDate()).slice(-2) + '.'
  );
};

const readExcelTopic = (row) => {
  return {
    abbreviation: row[ABBREVIATION_COLUMN_INDEX],
    name: row[DESCRIPTION_COLUMN_INDEX],
    is_closed: row[IS_CLOSED_COLUMN_INDEX] === CLOSED,
    id: parseInt(row[ID_COLUMN_INDEX]),
    version_number: row[VERSION_NUMBER_NO_COLUMN_INDEX],

    parentId: row[PARENT_ID_COLUMN_INDEX],
    // This is needed for formatting when syncing Excel data.
    has_main_topic: row[PARENT_ID_COLUMN_INDEX] === HAS_NO_PARENT ? false : true,
    parentRowNo: row[PARENT_ROW_NO_COLUMN_INDEX],
    relationString: row[RELATION_STRING_COLUMN_INDEX],
    rowNo: row[ROW_NO_COLUMN_INDEX],
    has_errors: row[ERROR_COLUMN_INDEX] ? true : false,
    dataType: "T",
  };
};

const syncJsonRequest = async (url, method, body, showErrorMessage) => {
  console.log("body: ", body);
  try {
    const rawResponse = await fetch(url, {
      method,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (await getAccessToken()),
        "Content-Type": "application/json",
        'X-CSRFToken': getCookie('csrftoken') ,
      },
      body: JSON.stringify(body),
    });

    const bodyMessage = await rawResponse.json();
    if (rawResponse.ok) return bodyMessage;

    if (bodyMessage.detail) return { error: bodyMessage.detail };

    console.error("Error - Response a szervertől: ", bodyMessage);
    return { error: JSON.stringify(bodyMessage) };
  } catch (err) {
    return { error: handleErrors(err, null, "Megszakadt a kapcsolat a szerverrel (6)!") };
  }
};

const handleDataChange = (event) => {
  console.log("eventHandler ran.");
  markChangedRows(event.address);
};

const getRangeToStar = (address) => {
  const strippedAddress = address.split("!")[address.split("!").length - 1];
  const splitAddress = strippedAddress.split(":");

  // Don't mark as modified, if the edited cell is outside of the editable range.
  if (ALPHABET.indexOf(splitAddress[0].replace(/\d+/g, "")) >= ALPHABET.indexOf(DONT_ALLOW_EDIT_FROM_COLUMN)) return;

  return splitAddress.length === 1
    ? `${WAS_MODIFIED_COLUMN_LETTER}${splitAddress[0].replace(/^\D+/g, "")}`
    : `${WAS_MODIFIED_COLUMN_LETTER}${splitAddress[0].replace(
        /^\D+/g,
        ""
      )}:${WAS_MODIFIED_COLUMN_LETTER}${splitAddress[1].replace(/^\D+/g, "")}`;
};

const markChangedRows = async (address) => {
  await excelWrapper(async (context) => {
    const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
    const rangeToStar = getRangeToStar(address);

    if (!rangeToStar) return;
    const rangeToStarRange = currentWorksheet.getRange(rangeToStar);
    rangeToStarRange.load("values");
    await context.sync();

    const starsToAdd = [];
    for (let i = 0; i < rangeToStarRange.values.length; i++) starsToAdd.push([WAS_MODIFIED]);

    rangeToStarRange.values = starsToAdd;
    await context.sync();
  });
};

const momentToUnixTimeStamp = (timestamp) => {
  return (timestamp - 25569) * 86400 * 1000;
};

const sortSheetsByDate = (a, b) => {
  return compareDates(a.name, b.name);
};

const compareDates = (a, b) => {
  const a_timestamp = new Date(a).getTime();
  const b_timestamp = new Date(b).getTime();

  return !a_timestamp ? 1 : !b_timestamp ? -1 : b_timestamp - a_timestamp;
};

const setShowLoading = (isShow) => {
  if (isShow) document.getElementById("loading").style.display = "flex";
  else document.getElementById("loading").style.display = "none";
};

const showLoadingWrapper = async (func) => {
  try {
    setShowLoading(true);
    const result = await func();
  } finally {
    setShowLoading(false);
  }
};

const getSyncedData = (sheetToSyncData, latestData) => {
  for (const line of latestData) {
    const rowFoundIndex = sheetToSyncData.findIndex((excelRow) => {
      return excelRow.id == line.id && excelRow.dataType == line.dataType;
    });
    if (rowFoundIndex === -1) {
      // If a row was not found in excelData of this type of this ID,
      // that means that it's a new row added by someone else,
      // and should be added to the current cooperation sheet too.
      const isTopic = line.dataType === "T";
      let insertUnderRowNo;
      let parent;
      if (isTopic) {
        parent = sheetToSyncData.find((excelRow) => excelRow.id == line.main_topic && excelRow.dataType == "T");
        insertUnderRowNo = getTopicInsertAfterRow(sheetToSyncData, parent ? parent.rowNo : HAS_NO_PARENT).insertAfter;
      } else {
        parent = sheetToSyncData.find((excelRow) => excelRow.id == line.main_entry && excelRow.dataType == "E");
        if (!parent) {
          parent = sheetToSyncData.find((excelRow) => excelRow.id == line.topic && excelRow.dataType == "T");
        }
        insertUnderRowNo = getEntryInsertAfterRow(sheetToSyncData, parent.rowNo, false).insertAfter;
      }

      const insertUnderIndex = sheetToSyncData.findIndex((row) => row.rowNo == insertUnderRowNo);
      line.rowNo = sheetToSyncData[insertUnderIndex].rowNo + 1;
      line.relationString = parent ? `${parent.relationString}.${line.rowNo}` : line.rowNo;

      sheetToSyncData = addNewLineToSheetData(sheetToSyncData, insertUnderIndex, line);
    } else {
      // If it's not new, check if it should be updated or not, and act accordingly.
      const shouldBeUpdated =
        sheetToSyncData[rowFoundIndex].version_number < line.version_number &&
        !sheetToSyncData[rowFoundIndex].wasModified;

      if (shouldBeUpdated) {
        line.rowNo = sheetToSyncData[rowFoundIndex].rowNo;
        line.relationString = sheetToSyncData[rowFoundIndex].relationString;
        sheetToSyncData[rowFoundIndex] = line;
      }
    }
  }

  console.log("sheetToSyncData", sheetToSyncData);
  return sheetToSyncData;
};

const addNewLineToSheetData = (sheetData, insertUnderIndex, newRow) => {
  const fromRowNo = newRow.rowNo;

  /* Corrects the row's rowNo and relationString that
  would be missaligned due to the insert. */
  sheetData.forEach((row) => {
    if (row.rowNo >= fromRowNo) {
      row.rowNo++;
      row.relationString = row.relationString
        .split(".")
        .map((ancestorRowNo) => {
          ancestorRowNo = parseInt(ancestorRowNo);
          if (ancestorRowNo >= fromRowNo) {
            ancestorRowNo++;
          }

          return ancestorRowNo;
        })
        .join(".");
    }

    return row;
  });

  /* Inserts new line. */
  sheetData.splice(insertUnderIndex, 0, newRow);
  return sheetData;
};

const syncExcelWithServer = async (context, sheetToSync, cooperationData) => {
  const excelData = await readExcelData(context, sheetToSync, true);
  const latestData = await orderCooperationDataRows(cooperationData);
  const dataToOverwriteWith = getSyncedData(excelData, latestData);

  // Convert the synced data back to correct order and cell values.
  const dataToInsert = dataToOverwriteWith.reverse().map((line) => getCorrectTypeCells(line));
  await insertData(false, context, sheetToSync, "A", HEADER_CELLS_HEIGHT + 1, dataToInsert, true);
  const footerStartRowNo = HEADER_CELLS_HEIGHT + dataToInsert.length + 1;
  loadFooter(sheetToSync, footerStartRowNo);
};

export {
  getSyncedData,
  syncExcelWithServer,
  handleDataChange,
  synchroniseCooperation,
  readExcelTopic,
  readExcelEntry,
  showLoadingWrapper,
};
