import React from 'react'
import { useState, useEffect } from 'react';
import { BasicButton } from "./styles/ButtonStyles";
import { fetchData } from "./Utils";
import useDialogStore from "./stores/useDialogStore";
import { URL } from "../utils/constans";
import moment from 'moment';

// Icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function MailTo({coopId, orderNum, project}) {
    const dialogStore = useDialogStore();
    const [recipientList, setRecipientList] = useState([]);
    const [ccList, setCcList] = useState([]);
    const [emailBody, setEmailBody] = useState("");

  const fetchCoopData = async (currentCoop) => {
    const nextDate = moment(currentCoop.date).add(7,'days').format('YYYY.MM.DD.');
    const resp = await fetchData(`${URL}/api/compose-cooperation-mail/${coopId}/`, null, dialogStore.showErrorMessage);
    if (resp !== undefined) {
      setRecipientList(resp.recipients_list.join(';'));
      setCcList(resp.cc_list.join(';'))
      let updatedEmailBody = resp.email_body.replace(encodeURIComponent("<cooperation_number>"), orderNum);
      updatedEmailBody = updatedEmailBody.replace(encodeURIComponent("<cooperation_date>"), nextDate);
      updatedEmailBody = updatedEmailBody.replace(encodeURIComponent("<cooperation_place>"), currentCoop.place);
      updatedEmailBody = updatedEmailBody.replace(encodeURIComponent("<cooperation_url>"), currentCoop.attachments_url);
      setEmailBody(updatedEmailBody);
    }
  }

  useEffect(() => {
    const currentCoop = project.cooperations.filter(coop => coop.id === coopId)[0];
    if (currentCoop) fetchCoopData(currentCoop);
  },[coopId, orderNum])

  // Replace the string "\n" with the character '\n'.
  const formattedBody = emailBody.replaceAll("\\n", '\n');
  return (
    <a target="_blank" content="frame-src 'self'" href={`mailto:${recipientList}${ccList.length > 0 ? '?cc='+encodeURIComponent(ccList)+'&' : '?'}subject=${encodeURIComponent(project.name)} ${orderNum}. Kooperáció&body=${encodeURIComponent(formattedBody)}`}>
        <BasicButton color="success" variant="contained">
            <MailOutlineIcon className="icon" sx={{ color: 'white' }}/>
        </BasicButton>
    </a>
  )
}
