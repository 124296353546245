import * as React from 'react';
import moment from 'moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useCooperationStore from "./stores/useCooperationStore";
import { loadCooperationsIntoExcel } from "../utils/excel_utils";
import { showLoadingWrapper } from '../utils/syncronisation_utils';

export default function CoopDateDialog({setActive, latestCoopDate, coopDateLimit, setCoopDateLimit, project, cooperationStore, showErrorMessage}) {
  const setActiveCooperation = useCooperationStore((state) => state.setActiveCooperation);

  const confirmLoadCooperations = async () => {
    showLoadingWrapper(async () => {
      await loadCooperationsIntoExcel(project, cooperationStore, latestCoopDate, coopDateLimit, showErrorMessage, setActiveCooperation);
      project.cooperations.length > 0 ? setActive("editCooperationInExcel") : setActive("allProjects")
    })
  }

  return (
    <div>
      <Dialog open={true} onClose={() => setActive("editProjectInExcel")}>
        <DialogTitle>Kooperációk betöltése</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mr: "5px"}}>
          Adjon meg egy dátumot, amelytől frissebb kooperációkat be szeretne tölteni
          </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                    locale="hu"
                    id="1"
                    label="Dátum megadása"
                    inputFormat="YYYY-MM-DD"
                    toolbarFormat="MMMM DD"
                    value={coopDateLimit}
                    onChange={(e) => setCoopDateLimit(moment(e).format("YYYY-MM-DD"))}
                    renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 20px 10px 0', width: '350px'}}/>}
                    variant="outlined"              
                />
            </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActive("allProjects")}>Mégse</Button>
          <Button onClick={() => {confirmLoadCooperations()}}>Mentés</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}